import './css/leftContainer.scss'
import PlantDetail from './plantDetail';
import { Button, Icon, Select } from '@scuf/common';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../common/store/combinedReducer';
import { useEffect, useState } from 'react';
import { getAssignedRoles, getDetails, getOrgStatus, getUserRoles, removeSelectedVM, setActivePage, setAssignedRoles, setAssignedStatus, setLoading, setSelectedPlant, setSelectedView, setSignalR, setSiteID, setUserRoleStatus, setVMData } from '../../../store/action';
import _ from 'lodash';
import dataManagement from '../../../../common/assets/data-management.svg';
import userManagement from '../../../../common/assets/user-management.svg';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import config, { REQUIRED_PERMISSIONS } from '../../../../common/authentication/config';
import AuthProvider from '../../../../common/authentication/authService';
import { INotification } from '../../../store/model';
import { customToast } from '../../../../App';

const LeftContainer = () => {
    const dispatch = useDispatch();
    let connection: HubConnection;
    const index: any = localStorage.getItem('index') || 0;
    const activePage = useSelector((state: AppState) => {
        return state.reducer.activePage;
    });
    const userRoles = useSelector((state: AppState) => {
        return state.reducer.userRoles;
    });
    const orgData = useSelector((state: AppState) => {
        return state.reducer.orgData;
    });
    const orgsDropDownData = useSelector((state: AppState) => {
        return state.reducer.orgsDropDownData;
    });
    const selectedPlant = useSelector((state: AppState) => {
        return state.reducer.selectedPlant;
    });
    const orgDataStatus = useSelector((state: AppState) => {
        return state.reducer.orgDataStatus;
    });
    const assignedRolesStatus = useSelector((state: AppState) => {
        return state.reducer.assignedRolesStatus;
    });
    const permissions = useSelector((state: AppState) => {
        return state.reducer.permissions;
    });
    const userRolesStatus = useSelector((state: AppState) => {
        return state.reducer.userRolesStatus;
    });
    const signalRConnection = useSelector((state: AppState) => {
        return state.reducer.signalR;
    });
    const [selectedOrg, setSelectedOrg] = useState(selectedPlant);
    const [dom, setDom] = useState(false);
    const [signalrConnection, setSignalrConnection] = useState<HubConnection>();
    const [showUserManagement, setShowUserManagement] = useState(false);
    const [showDataManagement, setShowDataManagement] = useState(false);
    const [selectedData, setSelectedData] = useState<any>();

    const setUpSignalRConnection = async (orgName: any) => {
        connection = new HubConnectionBuilder()
            .withUrl(`${config.API_SERVICE}/notificationhub?siteId=${orgName}`, {
                accessTokenFactory: () => AuthProvider().getAccessToken()
            })
            .build();
        setSignalrConnection(connection);
        dispatch(setSignalR(connection));
        connection.on('PushMessage', (user: any, notification: INotification) => {
            if (notification.message) {
                customToast(notification.message, notification && notification.severity ? notification.severity : 'critical');
                dispatch(setLoading(true));
                dispatch(getOrgStatus(0));
            }
        });
        connection.start().then(() => {
            // eslint-disable-next-line no-console
            console.log('SignalR Connection Established');
        }).catch((err) => {
            console.error('Connection failed to start: ' + err);
        });
    };
    const closeConnection = async () => {
        console.log('state connection');
        if (signalRConnection && signalRConnection.state === HubConnectionState.Connected) {
            await signalRConnection.stop();
        }
        // connection
        signalRConnection?.onclose((error: any) => {
            console.error(error);
        });
    }

    useEffect(() => {
        setSelectedOrg(selectedPlant);
        const valuePermission: any = _.find(permissions, ['resources', selectedPlant]);
        setSelectedData(valuePermission);
        if(valuePermission && valuePermission?.permissions.length >0){

        const hasUserManagement = REQUIRED_PERMISSIONS.CAN_VIEW_USER_MANAGEMENT.every((p: string) =>
            valuePermission.permissions.includes(p)            
        );
        
        const hasDataManagement =  REQUIRED_PERMISSIONS.TRANSFER_DATA_OR_FILES_IN_AND_OUT.every((p: string) =>
            valuePermission.permissions.includes(p)            
        );
        if (hasUserManagement) setShowUserManagement(true);
        if (hasDataManagement) setShowDataManagement(true);
        {console.log("===>105",hasUserManagement,hasDataManagement,"valuePermission",valuePermission.permissions,"REQUIRED_PERMISSIONS.TRANSFER_DATA_OR_FILES_IN_AND_OUT",REQUIRED_PERMISSIONS.TRANSFER_DATA_OR_FILES_IN_AND_OUT,)}

        }
    }, [orgsDropDownData,permissions]);

    useEffect(() => {
        if (signalRConnection === undefined || !signalRConnection || !signalRConnection?.state) {
            dispatch(setSignalR(connection));
        }
    }, [signalrConnection, signalRConnection]);

    useEffect(() => {
        if (orgDataStatus === 200) {
            orgData && orgData.length > 0 && orgData.forEach((data: any) => {
                if (data && data.siteId === selectedOrg) {
                    if (data.IsVCDAlive === false)
                        customToast('This site is not available currently, please try after sometime.', 'critical');
                    if (signalRConnection && signalRConnection.state === HubConnectionState.Disconnected || signalRConnection === undefined || !signalRConnection || !signalRConnection?.state) // connect only if the signalr is disconnected as it'll create more wss calls
                        {console.log('in a signalr connection');
                        setUpSignalRConnection(data.name);
                }
                } else if (selectedOrg == '') {
                    if (signalRConnection && signalRConnection.state === HubConnectionState.Disconnected || signalRConnection === undefined || !signalRConnection || !signalRConnection?.state) // connect only if the signalr is disconnected as it'll create more wss calls
                        {console.log('in a signalr connection');
                        setUpSignalRConnection(orgData && orgData[0]?.name);}
                }
            });
        }
        setDom(!dom);
    }, [orgDataStatus, orgData]);

    const navigateToSelectedPage = (value: any) => {
        const selectedView = { selectedView: value, plantName: selectedData && selectedData.name, activePage };
        if (value === 'UserManagement' && selectedPlant !== '') {
            if (userRolesStatus === 0) {
                dispatch(getUserRoles({ authorization: localStorage.getItem('access_token'), siteId: selectedPlant }));
            }
            if (assignedRolesStatus === 0) {
                dispatch(getAssignedRoles({ authorization: localStorage.getItem('access_token'), siteId: selectedPlant }));
            }
        }
        dispatch(setSelectedView(selectedView));
    }

    const changeOrgData = (value: any) => {
        closeConnection();
        const valuePermission: any = _.find(permissions, ['resources', value]);
        orgsDropDownData?.forEach((data: any, index: any) => {
            if (data && data?.value === value && valuePermission) {
                localStorage.setItem('index', index);
                dispatch(setLoading(true));
                dispatch(getOrgStatus(0));
                dispatch(setAssignedStatus(0));
                setSelectedOrg(value);
                dispatch(setSelectedPlant(value));
                dispatch(setActivePage(index));
                dispatch(setSiteID(value));
                orgData?.vmDetails?.forEach((element: any) => {
                    element.checked = false;
                });
                dispatch(removeSelectedVM());
                // const hasUserManagement = REQUIRED_PERMISSIONS.CAN_VIEW_USER_MANAGEMENT.every((p: string) =>
                //     valuePermission.permissions.includes(p)            
                // );
                // const hasDataManagement =  REQUIRED_PERMISSIONS.TRANSFER_DATA_OR_FILES_IN_AND_OUT.every((p: string) =>
                //     valuePermission.permissions.includes(p)            
                // );
                // if (hasUserManagement) setShowUserManagement(true);
                // else setShowUserManagement(false);
                // if (hasDataManagement) setShowDataManagement(true);
                // else setShowDataManagement(false);
                // console.log('orgVal', data, value, valuePermission, _.find(valuePermission.permissions, ['permission', 'dp.site.datamanagement']));
            }
            {console.log("===>175",showDataManagement,showUserManagement)}

        });
    }

    return (
        <div className='container-wrapper'>
            <div className='left-header-wrapper'>
                {orgsDropDownData?.length > 0 ?
                    <>
                        {<Select id='manage-plant-wrapper' className='manage-plant-wrapper'
                            options={orgsDropDownData} defaultValue={orgsDropDownData[index]?.value}
                            onChange={(value: any) => changeOrgData(value)} />}
                        <div className='manage-plant'>
                            {showDataManagement ?
                                <Button type='primary' className={'button1-active'} icon={<img className='icon-plant' src={dataManagement} />} content='&nbsp; Data Management' iconPosition='left' size='small' onClick={() => navigateToSelectedPage('DataManagement')} />
                                : null}
                            {showUserManagement ?
                                <Button type='primary' className={'button1-active'} icon={<img className='icon-plant' src={userManagement} />} content='&nbsp; User Management' iconPosition='left' size='small' onClick={() => navigateToSelectedPage('UserManagement')} />
                                : null}
                        </div>
                    </>
                    : null
                }
            </div>
            <PlantDetail orgData={orgData[0]} />
        </div>
    )
};
export default LeftContainer;