import { call, put, takeLatest } from 'redux-saga/effects';
import { BLOB_SIZE_DASHBOARD_GET_DATA, DELETE_TABLE_DATA, DOWLOAD_DASHBOARD_SELECT_ROWS_DATA, GET_ASSIGNED_ROLES, GET_CREATE_SNAP_SHOT, GET_DASHBOARD_UPLOAD_VALIDATION, GET_DATA, GET_PERMISSION, GET_REBOOT, GET_RENAME_FOLDER_DATA, GET_RESTORE_SNAP_SHOT, GET_SHUTDOWN, GET_START, GET_TABLE_DATA, GET_TOKEN_CSRF, GET_UPDATE_USER_ROLES, GET_UPLOAD_CHUNK, GET_UPLOAD_FILE, GET_USER_DELETE, GET_USER_ROLES, GET_WMKS_DATA, RENAME_FILE_DATA, SET_NEW_USER_ROLE, getOrgStatus, setAssignedRoles, setAssignedStatus, setCSRFToken, setCreateSnapshot, setDashboardUploadValidationApi, setDeleteDataManagementRecord, setDeleteUser, setNewUserData, setPermissions, setRebootStatus, setRenameFileApiDashboard, setRenameFolderApiDashboard, setRestoreSnapshot, setShutDownData, setShutDownStatus, setStartData, setUpdateUserRoles, setUploadChunkApi, setUserData, setUserRoleStatus, setUserRoles, setVMData, setWMKSData, setblobSizedata, setdownloadRecordFile, settabledata, setuploaddata } from './action';
import { getPermissionAPI, getRebootAPI, getRestoreSnapShotAPI, getShutDownAPI, getVMDetailsAPI, getWMKSDataAPI, getCreateSnapshotAPI, getAssignedRolesAPI, getUserRolesAPI, getUserDeleteAPI, getNewUserAPI, getUpdateRoleUserAPI, getStartAPI, dashboardUploadValidation, fileRenameDashboardApi, folderRenameDashboardApi, getCSRFAPI, getDashboardBlobDataApi, getDeleteTableDataAPI, getUploadAPI, gettabledataAPI, uploadChunk } from './service';
import { AxiosResponse } from 'axios';


export function* handleVMDetails(requestParam: {
  type: string;
  payload: string;
}): any {
  // getting org data
  try {
    const resultData: any = yield call(
      getVMDetailsAPI,
      requestParam.payload
    );
    // if (resultData?.data !== undefined || resultData?.status === 200) {
    // console.log('result', resultData);
    yield put(getOrgStatus(resultData?.status));
    if (resultData && resultData.data) {
      yield put(setVMData((resultData.data)));
    } else {
      yield put(setVMData(([])));
    }
    // } else if (resultData?.response?.status !== 200) {
    //   yield put(getOrgStatus(resultData?.response?.status));
    // }
  } catch (error) { }
}
export function* handleTabledatadetails(requestParam: {
  type: string;
  payload: string
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      gettabledataAPI,
      requestParam.payload
    );

    yield put(settabledata(resultData?.data));

  } catch (error) {
    //  console.log("error123", error)
  }
}
//New

export function* handleDashboardBlobSize(requestParam: {
  type: string;
  payload: string
}) {
  try {
    // console.log("reachedsaga")
    const resultData: AxiosResponse<any> = yield call(
      getDashboardBlobDataApi,
      requestParam.payload

    );
    // console.log("blobSizeDashboardApi",resultData)
    yield put(setblobSizedata(resultData?.data));

  } catch (error) {
    console.log("error")
  }
}
export function* handleTabledatadetailsDelete(requestParam: {

  type: string;
  payload: string
}) {
  try {

    // debugger
    // debugger
    // console.log("reachedsaga")
    const resultData: AxiosResponse<any> = yield call(
      getDeleteTableDataAPI,
      requestParam.payload
    );
    // console.log("tabledata",resultData)
    // console.log("DeleteApiCheck", requestParam, resultData)
    yield put(setDeleteDataManagementRecord(resultData));

  } catch (error) {
    // console.log("error")
  }
}
// export function* handleDashboardDetailDownload(requestParam: {
//   type: string;
//   payload: string
// }) {
//   try {
//     const resultData: AxiosResponse<any> = yield call(
//       getDashboardDownloadDataAPI,
//       requestParam.payload

//     );
//     // console.log("tabledata",resultData)
//     yield put(setdownloadRecordFile(resultData));

//   } catch (error) {
//     console.log("error")
//   }
// }
export function* handlefileRenameDashboard(requestParam: {
  type: string;
  payload: string
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      fileRenameDashboardApi,
      requestParam.payload

    );
    // console.log("tabledata",resultData)
    yield put(setRenameFileApiDashboard(resultData));

  } catch (error) {
    // console.log("error")
  }
}
export function* handlefolderRenameDashboard(requestParam: {
  type: string;
  payload: string
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      folderRenameDashboardApi,
      requestParam.payload

    );
    // console.log("tabledata",resultData)
    yield put(setRenameFolderApiDashboard(resultData));

  } catch (error) {
    // console.log("error")
  }
}
export function* handleDashboardUploadValidation(requestParam: {
  type: string;
  payload: string
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      dashboardUploadValidation,
      requestParam.payload

    );
    // console.log("tabledata",resultData)
    yield put(setDashboardUploadValidationApi(resultData));

  } catch (error) {
    // console.log("error")
  }
}

//New End
export function* handleUploadData(requestParam: {
  type: string;
  payload: any;
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      getUploadAPI,
      requestParam.payload
    );
    yield put(setuploaddata(resultData));
  } catch (error) { }
}
export function* handleWMKSData(requestParam: {
  type: string;
  payload: any;
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      getWMKSDataAPI,
      requestParam.payload
    );
    yield put(setWMKSData(resultData));
  } catch (error) { }
}
export function* handlePermission(requestParam: {
  type: string;
  payload: any;
}): any {
  try {
    const resultData: any = yield call(
      getPermissionAPI,
      requestParam.payload
    );
    if (resultData !== undefined && resultData?.name !== 'AxiosError') {
      yield put(setPermissions(resultData));
      yield put(setUserData(resultData?.data));
    } else if (resultData?.name === 'AxiosError' || resultData === undefined) {
      yield put(setPermissions(resultData));
    }
  } catch (error) { }
}
export function* handleShutDown(requestParam: {
  type: string;
  payload: any;
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      getShutDownAPI,
      requestParam.payload
    );
    yield put(setShutDownStatus(resultData?.status));
  } catch (error) { }
}
export function* handleReboot(requestParam: {
  type: string;
  payload: any;
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      getRebootAPI,
      requestParam.payload
    );
    yield put(setRebootStatus(resultData?.status));
  } catch (error) { }
}
export function* handleStart(requestParam: {
  type: string;
  payload: any;
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      getStartAPI,
      requestParam.payload
    );
    yield put(setStartData(resultData?.status));
  } catch (error) { }
}
export function* handleCreateSnapShot(requestParam: {
  type: string;
  payload: any;
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      getCreateSnapshotAPI,
      requestParam.payload
    );
    yield put(setCreateSnapshot(resultData?.status));
  } catch (error) { }
}
export function* handleRestoreSnapShot(requestParam: {
  type: string;
  payload: any;
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      getRestoreSnapShotAPI,
      requestParam.payload
    );
    yield put(setRestoreSnapshot(resultData?.status));
  } catch (error) { }
}
export function* handleUserRoles(requestParam: {
  type: string;
  payload: any;
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      getUserRolesAPI,
      requestParam.payload
    );
    yield put(setUserRoleStatus(resultData?.status));
    yield put(setUserRoles(resultData));
  } catch (error) { }
}
export function* handleAssignedRoles(requestParam: {
  type: string;
  payload: any;
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      getAssignedRolesAPI,
      requestParam.payload
    );
    yield put(setAssignedStatus(resultData?.status));
    yield put(setAssignedRoles(resultData));
  } catch (error) { }
}
export function* handleUserDelete(requestParam: {
  type: string;
  payload: any;
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      getUserDeleteAPI,
      requestParam.payload
    );
    yield put(setDeleteUser(resultData));
  } catch (error) { }
}
export function* handleNewUserSearch(requestParam: {
  type: string;
  payload: any;
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      getNewUserAPI,
      requestParam.payload
    );
    yield put(setNewUserData(resultData));
  } catch (error) { }
}
export function* handleUpdateUserRole(requestParam: {
  type: string;
  payload: any;
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      getUpdateRoleUserAPI,
      requestParam.payload
    );
    yield put(setUpdateUserRoles(resultData));
  } catch (error) { }
}
export function* handleCSRF(requestParam: {
  type: string;
  payload: any;
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      getCSRFAPI,
      requestParam.payload
    );
    // console.log('result', resultData);
    yield put(setCSRFToken(resultData));
  } catch (error) { }
}

//Upload a chunk
export function* handleUploadChunk(requestParam: {
  type: string;
  payload: any;
}) {
  try {
    const resultData: AxiosResponse<any> = yield call(
      uploadChunk,
      requestParam.payload
    );
    // console.log('result', resultData);
    yield put(setUploadChunkApi(resultData));
  } catch (error) { }
}

export default function* watchAnalyticsLoad() {
  yield takeLatest(GET_DATA, handleVMDetails);
  yield takeLatest(GET_WMKS_DATA, handleWMKSData);
  yield takeLatest(GET_PERMISSION, handlePermission);
  yield takeLatest(GET_SHUTDOWN, handleShutDown);
  yield takeLatest(GET_REBOOT, handleReboot);
  yield takeLatest(GET_START, handleStart);
  yield takeLatest(GET_CREATE_SNAP_SHOT, handleCreateSnapShot);
  yield takeLatest(GET_RESTORE_SNAP_SHOT, handleRestoreSnapShot);
  yield takeLatest(GET_USER_ROLES, handleUserRoles);
  yield takeLatest(GET_ASSIGNED_ROLES, handleAssignedRoles);
  yield takeLatest(GET_USER_DELETE, handleUserDelete);
  yield takeLatest(SET_NEW_USER_ROLE, handleNewUserSearch);
  yield takeLatest(GET_UPDATE_USER_ROLES, handleUpdateUserRole);
  yield takeLatest(GET_TOKEN_CSRF, handleCSRF);
  //Data Management
  yield takeLatest(GET_TABLE_DATA, handleTabledatadetails)
  yield takeLatest(GET_UPLOAD_FILE, handleUploadData)
  yield takeLatest(GET_UPLOAD_CHUNK, handleUploadChunk)
  // New
  yield takeLatest(DELETE_TABLE_DATA, handleTabledatadetailsDelete);
  // yield takeLatest(DOWLOAD_DASHBOARD_SELECT_ROWS_DATA, handleDashboardDetailDownload)
  yield takeLatest(BLOB_SIZE_DASHBOARD_GET_DATA, handleDashboardBlobSize)
  yield takeLatest(RENAME_FILE_DATA, handlefileRenameDashboard)
  yield takeLatest(GET_RENAME_FOLDER_DATA, handlefolderRenameDashboard)
  yield takeLatest(GET_DASHBOARD_UPLOAD_VALIDATION, handleDashboardUploadValidation)
}