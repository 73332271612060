import { useSelector } from 'react-redux';
import { AppState } from '../../../common/store/combinedReducer';
import './header.scss';
import { Header } from '@scuf/common';
import { useNavigate } from 'react-router-dom';

const HeaderComponent = () => {
    const navigate = useNavigate();
    const userDetail: any = useSelector((state: AppState) => {
        return state.reducer.userInfo;
    });
    const selectedView = useSelector((state: AppState) => {
        return state.reducer.selectedView;
    });

    const goToAssistance = () => {
        window.open('/dashboard/userAssistance', '', 'noopener')?.focus();
    }

    const goToAcademy = () => {
        window.open('https://process.honeywell.com/us/en/services/training', '', 'noopener')?.focus();
    }
    return (
        <Header id='header' title={'Digital Prime Twin'} className='header-wrapper' menu={false}
            logoUrl={require('../../../common/assets/honeywell-logo.png')} collapseAt={700} data-testid='header-wrapper'>
            {/* {selectedView === 'Dashboard' ? */}
                <>
                    {/* <span className='content-text'>HEY IT'S NOT LIVE !  IT IS A DEV SYSTEM</span> */}
                    <div className='header-item'>
                        <Header.IconItem icon={'graduation'} description='E-Learning' className='header-icon-options' data-testid='header-graduation' onClick={() => goToAcademy()}></Header.IconItem>
                        <Header.IconItem icon={'badge-help'} description='Digital Prime Twin User Assistance' className='header-icon-options' data-testid='header-userAssistance' onClick={()=> goToAssistance()}></Header.IconItem>
                        {userDetail?.name !== '' ?
                            <Header.UserProfile firstName={userDetail?.name}  data-testid='header-userWrapper' role='Demolitions' id='header-logout'>
                                <Header.UserProfile.Item
                                    // disabled={true}
                                    id='user-name'
                                    data-testid='header-userName'
                                    className='user-name'>
                                    {userDetail?.name}
                                </Header.UserProfile.Item>
                                <Header.UserProfile.Item
                                    data-test='logout'
                                    id='logout-button'
                                    data-testid='header-logout'
                                    className='logout-button'
                                    onClick={() => {
                                        navigate('/logout');
                                    }}>Log Out</Header.UserProfile.Item>
                            </Header.UserProfile>
                            : null}
                    </div>
                </>
             {/* : null} */}
        </Header>
    );
};
export default HeaderComponent;