import DataManagement from './features/components/dashboard/LeftContainer/data-management/dataManagement';
import UserManagement from './features/components/dashboard/LeftContainer/userManagement';
import Dashboard from './features/components/dashboard/dashboard';
import UserAssistance from './features/components/header/userAssistance';
import Login from './features/components/login/login';
import Logout from './features/components/login/logout';
import RedirectLogin from './features/components/login/redirectLogin';
import RedirectPage from './features/components/login/redirectPage';
import VmConsole from './features/components/wmks/VmConsole';

export  const routesConfig = [
  {
    path: '/',
    element: <Login />,
    component: Login,
  },
  {
    path: '/none/login-callback',
    element: <RedirectPage />,
    component: RedirectPage,
  },
  {
    path: '/loginFailed',
    element: <RedirectLogin />,
    component: RedirectLogin,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    component: Dashboard,
  },
  {
    path: '/dashboard/userManagement',
    element: <UserManagement />,
    component: UserManagement,
  },
  {
    path: '/dashboard/dataManagement',
    element: <DataManagement />,
    component: DataManagement,
  },
  {
    path: '/dashboard/vmware/:siteId/:name',
    element: <VmConsole />,
    component: VmConsole
  },
  {
    path: '/dashboard/userAssistance',
    element: <UserAssistance/>,
    component: UserAssistance
  },
  {
    path: '/logout',
    element: <Logout />,
    component: Logout,
  }
];
