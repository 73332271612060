import './login.scss';
import AuthProvider from '../../../common/authentication/authService';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '@scuf/common';
import { AppState, combineReducer } from '../../../common/store/combinedReducer';
import { useEffect } from 'react';
import { setLoggedIn } from '../../store/action';
import { HubConnectionState } from '@microsoft/signalr';

export default function Logout() {
    const dispatch = useDispatch();

    const loading = useSelector((state: AppState) => {
        return state.reducer.loading;
    });

    const signalRConnection = useSelector((state: AppState) => {
        return state.reducer.signalR;
    });
    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(setLoggedIn(false));
                if (signalRConnection && signalRConnection.state === HubConnectionState.Connected) {
                    await signalRConnection.stop();
                }
                // AuthProvider().clearCookie('XSRF-TOKEN');
                localStorage.setItem('logged_user', JSON.stringify(false));
                AuthProvider().logout();
            } catch {
            }
        }
        fetchData();
    }, []);

    return (<>
        {loading ?
            <Loader>
                <div className='placeholder' />
            </Loader> : null}
    </>
    );
}