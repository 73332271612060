import { Button, Modal } from "@scuf/common"

export const DeleteFilePopup = (props: any) => {
    const { deletePopOpen, setDeleteOpen, handleDeleteApiFinalCall } = props;
    return (
        <Modal className='modal-content-dataManagement-wrapper' closeIcon={true} open={deletePopOpen} onClose={() => setDeleteOpen(false)} closeOnDimmerClick={false} closeOnDocumentClick={false} >
            <Modal.Content className='modal-content-dataManagement'>
                <span className='modal-text-dataManagement'>Are you sure you want to delete?</span>
            </Modal.Content>
            <Modal.Footer className='modal-footer-dataManagement'>
                <Button type="secondary" size='small' content="Cancel" className='cancel-secondary-dataManagement' onClick={() => setDeleteOpen(false)} />
                <Button type="primary" size='small' content="Yes" className='save-secondary-dataManagement' onClick={handleDeleteApiFinalCall} />
            </Modal.Footer>
        </Modal >
    )
}