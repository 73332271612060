import { useEffect, useState } from 'react';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../common/store/combinedReducer';
import '../../dashboardDetails.scss';
import '../css/dataManagement.scss'
import { Breadcrumb, Button, Icon, Loader } from '@scuf/common';
import {
    setSelectedView, gettabledata, setuploaddata, getuploaddata,
    blobSizeDashboard,
    setRenameFileApiDashboard, setDeleteDataManagementRecord,
    setRenameFolderApiDashboard, getDashboardUploadValidationApi,
    setDashboardUploadValidationApi,
    deleteDataManagementRecord,
    renameFileApiDashboard,
    renameFolderApiDashboard,
    setLoading,
    getCSRFToken,
    getDetails,
    getPermissions,
    setCSRFToken,
    setLoggedIn
} from '../../../../store/action';
import '@scuf/datatable/honeywell-dark/theme.css'

import { Progress } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { customToast } from '../../../../../App';
import _ from 'lodash';
import axios from 'axios';
import config from '../../../../../common/authentication/config';
import sha256Hash from '../../../../../common/utility/shaHash';
import { IFile, ISelectedRowDataStruc, ISelectedRowDownloadStruc } from '../../../../models/dataManagement.model';
import { DataManagementGrid } from './data-management-grid/DataManagementGrid';
import { DeleteFilePopup } from './popup-windows/DeleteFilePopup';
import { FileExistPopup } from './popup-windows/FileExistPopup';
import { FileValidatePopup } from './popup-windows/FileValidatePopup';
import { FolderNotEmptyPopup } from './popup-windows/FolderNotEmptyPopup';
import { RenameFilePopup } from './popup-windows/RenameFilePopup';
import { SymlinkErrorPopup } from './popup-windows/SymlinkErrorPopup';
import { NameLengthExceededPopup } from './popup-windows/NameLengthExceeded';
import { useNavigate } from 'react-router-dom';
import HeaderComponent from '../../../header/header';
import AuthProvider from '../../../../../common/authentication/authService';

const chunkSize = 5 * 1024 * 1024;

export default function DataManagement() {

    const siteId = useSelector((state: AppState) => {
        return state.reducer.selectedPlant;
    });

    const activePage = useSelector((state: AppState) => {
        return state.reducer.activePage;
    });
    const tdata = useSelector((state: AppState) => {
        return state.reducer?.tableData
    })

    //Blob Size
    const blobSize = useSelector((state: AppState) => {
        return state.reducer?.blobSizeDashboard
    })
    const uploadFileRes = useSelector((state: AppState) => {
        return state.reducer?.uploadfile
    })
    const deleteApiRes = useSelector((state: any) => {
        return state.reducer?.deleteDashboardRes
    })

    const downLoadApiRes = useSelector((state: any) => {
        return state.reducer
    })
    const renameFileApiRes = useSelector((state: any) => {
        return state?.reducer?.renameRes
    })
    const renameFolderApiRes = useSelector((state: any) => {
        return state?.reducer?.renameFolderRes
    })
    const uploadApiValidation = useSelector((state: any) => {
        return state?.reducer?.uploadValidationRes
    })
    const userDetail: any = useSelector((state: AppState) => {
        return state.reducer.userInfo;
    });
    const chunkUploadRes: any = useSelector((state: AppState) => {
        return state?.reducer?.chunkUploadRes;
    })
    const selectedPlant = useSelector((state: AppState) => {
        return state.reducer.selectedPlant;
    });
    const orgDataStatus = useSelector((state: AppState) => {
        return state.reducer.orgDataStatus;
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedRow, setSelectedRow] = useState<IFile[]>([]);
    const [pathcount, setPathcount] = useState(0)
    const [selectedRowData, setSelectedRowData] = useState<ISelectedRowDataStruc[]>([]);
    const [downloadSelectedRowData, setDownloadSelectedRowData] = useState<ISelectedRowDownloadStruc[]>([])
    // const [selectAll, setSelectAll] = useState(false);
    const [buttondisable, setButtondisable] = React.useState("item-upload-datamngmnt")
    const [buttondisabledownload, setButtondisabledownload] = React.useState("item-downloaddisable")
    const [buttondisabledelete, setButtondisabledelete] = React.useState("item-deletedisable")
    const [oldFileName, setOldFileName] = useState("")
    const [newFileName, setNewFileName] = useState<any>()
    // const [pathRenameFileSend, setPathRenameFileSend] = useState<any>()
    const [newFileValidationMsg, setNewFileValidationMsg] = useState("")
    const [newFileValidationLength, setNewFileValidationLength] = useState<any>()
    const [oldFileValidationMsg, setOldFileValidationMsg] = useState("")
    const [hideInput, setHideInput] = useState(false)
    const [hideInputValMsg, setHideInputValMsg] = useState("")
    const [click, setClicked] = useState(false)
    // temp changes
    const [xval, setxval] = useState<any>([])
    const [blobSizeState, setBlobSizeState] = useState<any>(0)
    const [finallist, setuniqList] = useState<any>([])
    const [open, setOpen] = useState(false)
    const [deletePopOpen, setDeleteOpen] = useState(false);
    const [currentpage, setCurrentPage] = useState(1)
    const [pagecount, setPageCount] = useState(0)
    const [loading, setLoadingPage] = useState(false)
    // const [siteId, setSiteId] = useState(selectedPlant);
    const [newFileKey, setfileKey] = useState("");
    // const [isEditInput, setEditInput] = useState(false);
    const [paths, setPaths] = useState<any>([])
    const [disablefunc, setDisablefunc] = useState(true);
    // const [dom, setDom] = useState(false);
    const [disablefuncupload, setDisablefuncUpload] = useState(false);
    const [preventUpload, setPreventUplaod] = useState(false);
    const [oldFileSize, setOldFileSize] = useState<any>([])
    const [oldFileType, setOldFileType] = useState<any>([])
    const [fileNameUpload, setFileNameUpload] = useState<any>("")
    const [uploadValidateOpen, setUploadValidateOpen] = useState(false)
    const [renameFileExtension, setRenameFileExtension] = useState<any>(null)
    const [userEmail, setUserEmail] = useState("")
    const [newArr, setNewArr] = useState<any>([]);
    const [fexist, setFexist] = useState(false);
    const inputEle = useRef<any>("");
    const [allowTableRowPerPage, setAllowTableRowPerPage] = useState<any>(10)
    const [consumedBlobState, setConsumedBlobState] = useState<any>(0)
    const [buttondisabledownloadcrsr, setButtondisabledownloadcrsr] = React.useState("disabled-button-cursor")
    // const [enterKeyStorage, setEnterKeyStorage] = useState("")
    const [emptyFldrName, setemptyFldrName] = useState(false);
    const [nameLength, setNameLength] = useState(false);
    // const [uploadAndScanMsg, setShowUploadAndScanMsg] = useState(false)
    const [orgNameVal, setOrgName] = useState('');
    const [showProgress, setShowProgress] = useState(false);
    const [progress, setProgress] = useState(0)
    const [fileSize, setFileSize] = useState(0)
    const [showRetry, setShowRetry] = useState(false);
    const [counter, setCounter] = useState(1);
    const [retryCount, setRetryCount] = useState(1);
    const [selectedLargeFile, setSelectedLargeFile] = useState<any>(null);
    const [startFileUpload, setStartFileUpload] = useState(false);
    const [showReset, setShowReset] = useState(false);
    const [downloadInPRogress, setDownloadInPRogress] = useState(false);
    const [fileHash, setFileHash] = useState('');
    const rowKey = useRef('');
    const chunkCount = useRef(0);
    const stopUpload = useRef(false);
    const [symlinkError, setSymlinkError] = useState(false);
    const [isNewFolderBtnDisabled, setIsNewFolderBtnDisabled] = useState<boolean>(false);

    // search
    const [filteredGridData, setFilteredGridData] = useState([]);
    const [searchBoxValue, setSearchBoxValue] = useState<any>("");
    const [isSearchDataEmpty, setIsSearchDataEmpty] = useState<boolean>(true);

    // download
    const [showDownloadProgress, setDownloadProgress] = useState(false);
    const [progressDownload, setProgressDownload] = useState(0);
    const [retryCountDownload, setRetryCountDownload] = useState(0);
    const [retryBtnState, hideRetryBtnState] = useState(true);
    const [fileNameVal, setFileName] = useState('');
    //set variable chunk and receivedLength to global scope
    //set below three varables to state variables
    const [chunks, setChunks] = useState<any>([]);
    const [receivedLength, setReceivedLength] = useState(0);
    const [contentLength, setContentLength] = useState(0);
    const [dom, setDom] = useState(false);
    var final: any = ""

    const permissions = useSelector((state: AppState) => {
        return state.reducer.permissions;
    });
    const plantName = useSelector((state: AppState) => {
        return state.reducer.plantName;
    });
    const csrf = useSelector((state: AppState) => {
        return state.reducer.csrf;
    });
    const csrfStatus = useSelector((state: AppState) => {
        return state.reducer.csrfStatus;
    });
    const permissionStatus = useSelector((state: AppState) => {
        return state.reducer.permissionStatus;
    });
    useEffect(() => {
        if (permissions?.length > 0 && localStorage.getItem('access_token') !== undefined && permissionStatus !== 500) {
            if (orgDataStatus === 0) {
                console.log('selectedplant', selectedPlant)
                fetchDetails(selectedPlant);
            } else if (orgDataStatus !== 0) {
                dispatch(setLoggedIn(false));
            }
        } else if (permissionStatus !== 0 && (permissions === null || (permissions?.length <= 0 && localStorage.getItem('access_token') !== undefined) || permissionStatus === 500)) {
            dispatch(setLoggedIn(false));
            // console.log('permission expired');
            navigate('/loginFailed');
        }
    }, [permissions, orgDataStatus]);

    const fetchDetails = (orgs: any) => {
        console.log('org', orgs)
        dispatch(getDetails({ authorization: localStorage.getItem('access_token'), orgs }));
        getData();
        dispatch(setLoggedIn(true));
        const orgNameData: any = _.find(permissions, ['resources', siteId]);
        if (orgNameData && orgNameData.resourceName) {
            setOrgName(orgNameData.resourceName);
        } else {
            orgNameData && setOrgName(orgNameData.resources)
        }
        setConsumedBlobState(0);
    }

    useEffect(() => {
        // console.log('csrfStatus', csrfStatus, csrf)
        if (csrfStatus === 200 || csrfStatus !== 0) {
            const sub: any = AuthProvider().getUserId();
            dispatch(setLoading(false));
            dispatch(setCSRFToken({ status: 0, data: false }));
            if (sub && csrf === true && permissions?.length === 0) {
                dispatch(getPermissions({ userSub: sub, authorization: localStorage.getItem('access_token') }));
            } else if (csrf === false) {
                // console.log('csrf expired');
                setTimeout(() => {
                    navigate('/loginFailed');
                }, 500)
            } else {
                dispatch(setLoading(false));
                // console.log('in else for csrf')
            }
        } else if (csrfStatus !== 200 && csrfStatus !== 0) {
            // console.log('csrf expired');
            setTimeout(() => {
                navigate('/loginFailed');
            }, 500)
        }
    }, [csrfStatus, csrf]);

    function handleRefresh(isRefreshed: boolean) {
        if (isRefreshed == true) AuthProvider().clearCookie('XSRF-TOKEN');
        AuthProvider().getRefreshToken()
            .then((resp: any) => {
                const tokenData = resp?.access_token;
                if (tokenData && resp && isRefreshed == true) {
                    dispatch(getCSRFToken({ authorization: localStorage.getItem('access_token') }));
                }
            })
    }
    useEffect(() => {
        if (localStorage.getItem('logged_user') === null || localStorage.getItem('logged_user') === JSON.stringify(false)) {
            navigate('/')
        }
        else {
            localStorage.setItem('logged_user', JSON.stringify(true));
            const selectedViewVal = { selectedView: 'UserManagement', plantName, activePage }
            dispatch(setSelectedView(selectedViewVal));
            if (permissions?.length === 0) {
                dispatch(setLoading(true));
                handleRefresh(true);
            }
            const orgNameData: any = _.find(permissions, ['resources', siteId]);
            if (orgNameData && orgNameData.resourceName) {
                setOrgName(orgNameData.resourceName);
            } else {
                orgNameData && setOrgName(orgNameData.resources)
            }
            setConsumedBlobState(0);
        }
    }, []);

    useEffect(() => {
        if (orgDataStatus !== 0) {
            dispatch(setLoading(false));
        }
    }, [orgDataStatus]);

    async function getData() {
        let data = {
            siteId,
            page: currentpage - 1,
            pageSize: 10000,    // TODO: Need to change this to property
            // parentPath: `%2F`
            parentPath: `${finallist.join('/')}/`
        }
        // setuniqList([]);
        if (xval === null || xval?.length === 0)
            await dispatch(gettabledata(data))

        setPageCount(tdata?.totalrecords)
        var startIndex = (currentpage - 1) * allowTableRowPerPage
        //var paginatedData = xval?.slice(startIndex, startIndex + allowTableRowPerPage)
        let currentPath = `${finallist.join('/')}`;
        let folders = new Set();
        var paginatedData = xval?.filter((item: any) => {
            // console.log("Current page UseEffect: ", "path", item.path, "current path", currentPath);
            if (item.path === currentPath || item.path === undefined) {
                //item.path = undefined;
                item.fullPath = item.path + '/' + item.fileName;
                return item;
            }
            else {
                let currFolder = item.path.replace(new RegExp(`^${currentPath}\\s*`), "");
                let _currTopLevelFolder = currFolder.split('/').filter(Boolean)[0];
                if (!folders.has(_currTopLevelFolder)) {
                    folders.add(_currTopLevelFolder);
                    //const pathArray = currFolder.split('/').filter(Boolean);                        
                    //item.path=pathArray[0];
                    //item.path = _currTopLevelFolder;
                    item.fileType = "folder";
                    //item.fileName=undefined;
                    return item;
                }
            }
        });


        setFilteredGridData(paginatedData);
        await dispatch(blobSizeDashboard({ authorization: localStorage.getItem('access_token'), container: siteId }));
    }
    useEffect(() => {
        getData();
    }, [currentpage]);

    useEffect(() => {
        CheckForUploadInprogress();
        checkIfResetRender();
        setFilteredGridData([]);
        //var paginatedData = xval?.slice(0, allowTableRowPerPage)

        let currentPath = `${finallist.join('/')}`;     //TODO: bring this all into one function for code reuse
        let folders = new Set();
        var paginatedData = xval?.filter((item: any) => {
            //console.log("path", item.path, "current path", currentPath);
            if (item.path === currentPath || item.path === undefined) {
                //item.path = undefined;
                item.fullPath = item.path + '/' + item.fileName;
                return item;
            }
            else {
                let currFolder = item.path.replace(new RegExp(`^${currentPath}\\s*`), "");
                let _currTopLevelFolder = currFolder.split('/').filter(Boolean)[0];
                if (!folders.has(_currTopLevelFolder)) {
                    folders.add(_currTopLevelFolder);
                    //const pathArray = currFolder.split('/').filter(Boolean);                        
                    //item.path=pathArray[0];
                    //item.path = _currTopLevelFolder;
                    item.fileType = "folder";
                    //item.fileName=undefined;
                    return item;
                }

            }
        });

        setFilteredGridData(paginatedData);
    }, [xval]);

    useEffect(() => {
        if (startFileUpload && fileNameUpload !== '') {
            uploadFile();
            setShowReset(true);
            setPreventUplaod(true);
        }
    }, [startFileUpload]);

    const dateFormatFun = (utcDate: any) => {
        const options: any = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        const formatted = new Date(utcDate).toLocaleDateString('en-US', options);
        const dateParts = formatted.substring(0, formatted.indexOf(',')).split(' ').reverse().join(' ');
        // Return the formatted date with uppercase month abbreviation and desired format
        const formattedDate = dateParts + formatted.substr(formatted.indexOf(',') + 1);
        return formattedDate;
    }

    const checkIfResetRender = () => {
        var tempxval = xval?.length > 0 ? xval.filter((item: any) => { return item.status === "FILE_UPLOAD_INPROGRESS" && item.updatedBy === userEmail }) : [];
        //write logic to get email id
        if (tempxval.length > 0) {
            setShowReset(true);
            setPreventUplaod(true);
        }
    }

    const navigateToPreviousPage = (pageName: any, datax: any, index: any) => {
        if (pageName != null && pageName != undefined) {
            // let index = finallist?.indexOf(pageName)
            finallist?.splice(index + 1, finallist?.length - index + 1)
            let previousfinal = finallist?.join('/')
            setCurrentPage(0);
            let data = {
                siteId,
                page: 0,
                pageSize: 10000,
                parentPath: `${previousfinal}%2F`
            }
            dispatch(gettabledata(data))
        }
    }
    function handlepagination(event: any) {
        setCurrentPage(event);
    }
    const navigateToDatamanagement = (jsonData: any) => {
        if (jsonData != null && jsonData != undefined) {
            setCurrentPage(0)
            finallist?.splice(0, finallist?.length)
            setuniqList([...finallist]);
            // handlepagination(1)
            let data = {
                siteId,
                page: 0,
                pageSize: 10000,
                // parentPath: `%2F`
                parentPath: `${finallist.join('/')}/`
            }
            dispatch(gettabledata(data))

            setxval(jsonData)
        }
    }

    const CheckForUploadInprogress = () => {
        //write logic to get the email id
        var tempxval = xval?.length > 0 ? xval.filter((item: any) => { return item.status === "FILE_UPLOAD_INPROGRESS" }) : [];
        //write logic to get email id
        if (tempxval.length > 0) {
            setPreventUplaod(true);
        }
    }

    const navigateToDashboard = async (plantName: string) => {
        navigate('/dashboard');
        // const selectedView = { selectedView: 'Dashboard', plantName, activePage }
        stopUpload.current = true;
        // await setDom(!dom);
        // await dispatch(gettabledata(null))
        // await dispatch(setSelectedView(selectedView));
    }

    useEffect(() => {
        if (tdata?.statuscode === 200) {
            let result = tdata?.message.map((item: any, index: number) => {
                return ({
                    ...item,
                    updatedAt: dateFormatFun(item.updatedAt) ? dateFormatFun(item.updatedAt) : ""
                })
            });
            setSelectedRow([])
            setxval(result)
            CheckForUploadInprogress();
            setLoadingPage(false)
            setUserEmail(userDetail.email)
            // setButtondisable("item-upload-datamngmnt")
            // setButtondisabledownload("item-downloaddisable")
            // setButtondisabledelete("item-deletedisable")
            setDisablefunc(true)
            setDisablefuncUpload(false)
        } else if (tdata?.statuscode !== 0) {
            setLoadingPage(false)
        }
    }, [tdata])

    // pagination useeffect
    useEffect(() => {
        setPageCount(tdata?.totalrecords)
    }, [tdata?.totalrecords])

    useEffect(() => {
        setBlobSizeState(blobSize)
        const convertDataGB = (val: any, type: any) => {
            let gbVal;
            if (type?.toLowerCase() === "kb") {

                gbVal = ((val / 1024) / 1024)
            }
            if (type?.toLowerCase() === "mb") {
                gbVal = (val / 1024)
            }
            return gbVal
        }
        function splitFun(input: any) {
            const splitArray = input.match(/([\d\.]+)(.*)/);
            return {
                numeric: splitArray[1],
                nonnumeric: splitArray[2],
            };
        }
        const blobFlow = async () => {
            if (blobSize?.message?.consumedSize === '0Bytes') {
                setConsumedBlobState(0)
            } else if (blobSize?.message?.consumedSize) {
                let consize = await splitFun(blobSize?.message?.consumedSize)
                if (consize.nonnumeric.toLowerCase() != 'gb') {
                    let resConsume = await convertDataGB(consize.numeric, consize.nonnumeric)
                    if (resConsume) {
                        let roundNum = await Math.round(resConsume)
                        await setConsumedBlobState(resConsume)
                    }
                } else {
                    if (consize.numeric) {
                        await setConsumedBlobState(consize.numeric)
                    }
                }
            }
        }
        blobFlow()
    }, [blobSize])

    let allosize = blobSizeState?.message?.allocatedSize;

    const AllocatedSize = parseInt(allosize?.slice(0, allosize?.length - 2))

    //Handle rename api response
    if (renameFileApiRes != null && !Array.isArray(renameFileApiRes)) {
        if (renameFileApiRes?.status === 200) {
            // setDom(!dom);
            setLoadingPage(false)
            let data = {
                siteId,
                page: currentpage - 1,
                pageSize: 10000,
                // parentPath: "%2F"
                parentPath: `${finallist.join('/')}/`
            }
            // setuniqList([]);
            customToast(renameFileApiRes?.data?.message === null ? renameFileApiRes?.data?.status : renameFileApiRes?.data?.message, 'success')
            dispatch(setRenameFileApiDashboard(null));
            dispatch(gettabledata(data))
            setSelectedRow([])
            // setButtondisable("item-upload-datamngmnt")
            // setButtondisabledownload("item-downloaddisable")
            // setButtondisabledelete("item-deletedisable")
            setDisablefunc(true)
            setDisablefuncUpload(false)
            setOldFileSize(null)
            setOldFileType(null)
        }

    }
    //Handle rename folder api response
    if (renameFolderApiRes != null && !Array.isArray(renameFolderApiRes)) {
        if (renameFolderApiRes?.status === 200) {
            // setDom(!dom);
            setLoadingPage(false)
            setSelectedRow([])
            let data = {
                siteId,
                page: currentpage - 1,
                pageSize: 10000,
                // parentPath: "%2F"
                parentPath: `${finallist.join('/')}/`
            }

            // setuniqList([])
            customToast(renameFolderApiRes?.data?.status ? renameFolderApiRes?.data?.status : renameFolderApiRes?.data?.message?.responseMessage, 'success')
            dispatch(setRenameFolderApiDashboard(null));
            dispatch(gettabledata(data))
        }
    }
    useEffect(() => {
        async function getDataVal() {
            //Handle Delete api response
            if (deleteApiRes != null && !Array.isArray(deleteApiRes)) {

                if (deleteApiRes?.status === 200 || deleteApiRes?.data?.statuscode === 200) {
                    setLoadingPage(false)

                    let data = {
                        siteId,
                        page: currentpage - 1,
                        pageSize: 10000,
                        // parentPath: "%2F"
                        parentPath: `${finallist.join('/')}/`
                    }
                    // setuniqList([]);
                    customToast(deleteApiRes?.data?.message === null ? deleteApiRes?.data?.status : deleteApiRes?.data?.message, 'success')
                    dispatch(setDeleteDataManagementRecord(null));
                    await dispatch(gettabledata(data))
                    await dispatch(blobSizeDashboard({ authorization: localStorage.getItem('access_token'), container: siteId }))
                    // setButtondisable("item-upload-datamngmnt")
                    // setButtondisabledownload("item-downloaddisable")
                    // setButtondisabledelete("item-deletedisable")
                    setDisablefunc(true)
                    setDisablefuncUpload(false)
                    setSelectedRow([])
                }
            }
        }
        getDataVal();
    }, [deleteApiRes])

    useEffect(() => {
        async function getDataUpload() {
            // console.log('uploadFileRes', uploadFileRes, uploadValidateOpen, uploadApiValidation)
            if (uploadFileRes?.statuscode === 200) {
                // setDom(!dom);
                let data = {
                    siteId,
                    page: currentpage - 1,
                    pageSize: 10000,
                    // parentPath: "%2F"
                    parentPath: `${finallist.join('/')}/`
                }
                // setuniqList([]);
                setLoadingPage(false)
                // setShowUploadAndScanMsg(false)
                customToast(uploadFileRes?.message, 'success')
                dispatch(setuploaddata(null));
                await dispatch(gettabledata(data))
                await dispatch(blobSizeDashboard({ authorization: localStorage.getItem('access_token'), container: siteId }))
                setDisablefuncUpload(false)
                setFileNameUpload("")

            } else if (uploadFileRes?.statuscode === 400) {
                customToast(uploadFileRes?.message, 'critical')
            }

            if ('setDownloadRes' in downLoadApiRes === true) {
                // setDom(!dom);
                setLoadingPage(false)
            }
            if (uploadApiValidation?.status === 200) {
                setLoadingPage(false);
                if (uploadApiValidation.data?.statuscode === 200) {
                    setLoadingPage(false)
                    setUploadValidateOpen(true);
                    rowKey.current = uploadApiValidation.data?.data?.rowKey;
                    dispatch(setDashboardUploadValidationApi(null))
                }
                else if (uploadApiValidation.data?.statuscode === 400) {
                    setLoadingPage(false)
                    setUploadValidateOpen(false)
                    setStartFileUpload(true);
                    dispatch(setDashboardUploadValidationApi(null))
                }
            }
        }
        getDataUpload();
    }, [uploadApiValidation?.status || uploadApiValidation || uploadFileRes || uploadFileRes?.statuscode || downLoadApiRes || tdata?.statuscode])

    useEffect(() => {
        if (chunkUploadRes.statusCode === 400) {
            console.log(chunkUploadRes);
        } else if (chunkUploadRes.statusCode === 200) {
            console.log(chunkUploadRes);
        } else {
            console.log(chunkUploadRes);
        }
    }, [chunkUploadRes])

    useEffect(() => {
        if (chunkUploadRes?.response?.status === 400) {
            customToast(chunkUploadRes.response.data, "critical");

        } else if (chunkUploadRes?.response?.status == 500) {
            console.log(chunkUploadRes.response);
        }

    }, [chunkUploadRes])

    function handlechangelink(datas: any, datax: any) {
        let currentPath = `${finallist.join('/')}/`;
        let currFolders = datas?.path.replace(new RegExp(`^${currentPath}\\s*`), "");
        let currTopLevelFolder = currFolders.split('/').filter(Boolean)[0];
        if (currTopLevelFolder === undefined || currTopLevelFolder === '') {
            currTopLevelFolder = currFolders;
        }
        finallist.push(currTopLevelFolder);
        final = finallist.join('/')
        setCurrentPage(0);
        let data = {
            siteId,
            page: 0,
            pageSize: 10000,
            parentPath: `${final}%2F`
        }
        setLoadingPage(true)
        dispatch(gettabledata(data))
    }

    // upload File
    const uploadValidationPopupEvent = async (event: any) => {
        resetFileInput();
        rowKey.current = '';
        event.preventDefault();
        setUploadValidateOpen(false)
        await setFileNameUpload("")
        return
    }
    const filealreadyexistEvent = () => {
        setFexist(false);
        inputEle.current.focus();
    }
    // Folder Name can not be empty
    const folderNameCantBeEmptyEvent = () => {
        setemptyFldrName(false);
        inputEle.current.focus();
    }
    const nameLengthEvent = () => {
        setNameLength(false);
        resetChunkProperties();
    }
    // Refresh Button Functionality
    const refreshCurrentPageOfTable = async () => {
        setLoadingPage(true)
        let data = {
            siteId,
            page: currentpage - 1,
            pageSize: 10000,
            // parentPath: "%2F"
            parentPath: `${finallist.join('/')}/`
        }
        // setuniqList([]);
        await dispatch(gettabledata(data))
        await dispatch(blobSizeDashboard({ authorization: localStorage.getItem('access_token'), container: siteId }))
    }
    //Symlink Error
    const handleSymlinkErrorPopup = (event: any) => {
        event.preventDefault();
        setSymlinkError(false);
    }

    const resetChunkProperties = () => {
        setProgressDownload(0);
        setDisablefuncUpload(false);
        setDownloadInPRogress(false);
        setShowProgress(false)
        setProgress(0);
        setStartFileUpload(false);
        stopUpload.current = false;
        setDownloadProgress(false);
        //counter = 1;
        // rowKey = "";
        setCounter(1);
        // console.log(counter);
        setShowRetry(false);
        rowKey.current = '';
        setFileSize(0);
        setRetryCount(1);
        setSelectedLargeFile(null);
        setPreventUplaod(false);
        setShowReset(false);
        // setShowUploadAndScanMsg(false);
        chunkCount.current = 0;
        resetFileInput();
    }

    const resetFileInput = () => {
        var fileInput = document.getElementById('browse-file') as HTMLInputElement;
        fileInput.value = '';

    }

    const handleDeleteApiFinalCall = async () => {
        await setLoadingPage(false)
        await setDeleteOpen(false)

        await setLoadingPage(true)

        const result = selectedRowData.map((item: any, index: number) => {

            //prepare delete based on selection and ui search status
            let _path = "";
            let _fileName = "";
            if (!isSearchDataEmpty) {
                _fileName = item.fileName;
                _path = item.path;
            }
            else if (item.fileType === "folder") {
                let currentPath = `${finallist.join('/')}/`;
                let currFolder = item.path.replace(new RegExp(`^${currentPath}\\s*`), "");
                let _currTopLevelFolder = currFolder.split('/').filter(Boolean)[0];
                _path = (currentPath !== '/' ? currentPath : "") + _currTopLevelFolder;
            }
            if (item.fileType !== "folder") {
                _fileName = item.fileName;
                _path = item.path;
            }
            return (
                {
                    "fileName": _fileName,
                    "path": _path,
                    "rowKey": item?.rowKey ? item?.rowKey : "", // Checked API not being used in backend !!!
                    "partitionKey": item?.partitionKey ? item?.partitionKey : "" // Checked API not being used in backend !!!
                }
            )
        })

        const data = {
            container: selectedRowData[0].containerName ? selectedRowData[0].containerName : "",
            updatedBy: userEmail,
            deleteModel: result
        }
        await dispatch(deleteDataManagementRecord({ authorization: localStorage.getItem('access_token'), siteid: siteId, param: data }))
    }

    const verifyFile = (file: any) => {
        var maxFileSize = 2147483648;
        if (file.size > maxFileSize) {
            customToast("File Size Is Exceeding 2GB. Please Upload A File Smaller Than 2GB.", "critical");
            return false;
        }
        //Symlink code 
        if (file.type === 'application/x-symlink' || file.type === 'application/x-hardlink') {
            setSymlinkError(true);
            return false;
        }
        return true;
    }

    //Event Before Upload api
    const eventBeforeUploadApi = async (event: any) => {
        console.log('event', event)
        if (event.target.files.length > 0) {
            setLoadingPage(true);
            var File = event.target.files
            const _file = File[0];
            const parentPath: any = `${finallist.join('/')}`;
            // console.log('parentPath', parentPath, parentPath.length)
            const pathName: any = parentPath + '/' + File[0].name;
            const continueDownload = verifyFile(_file);
            if (continueDownload && pathName?.length <= 240) {
                const data: any = {
                    siteid: siteId,
                    filename: File[0].name,
                    parentPath: `${finallist.join('/')}`
                }

                const createdFileHash = await sha256Hash().readFile(_file);

                setFileHash(createdFileHash);
                await dispatch(getDashboardUploadValidationApi({ authorization: localStorage.getItem('access_token'), params: data, siteid: siteId }))

                setFileSize(_file.size)
                setFileNameUpload(_file.name)
                const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
                chunkCount.current = _totalCount;
                setSelectedLargeFile(_file);
            } else if (pathName?.length > 240) {
                setLoadingPage(false);
                setNameLength(true);
            }
            else {
                setLoadingPage(false);
                resetChunkProperties();
            }
        } else {
            setLoadingPage(false);
        }
    }

    const cancelUploadAPi = async () => {
        const apiConfig = {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}`, siteId }
        };
        await axios.get(`${config.API_SERVICE}/FileManagement/CancelUpload/largefileupload`, apiConfig)
            .then(response => {
                // Handle the response
                // console.log('Response:', response.data);
                rowKey.current = response.data.rowKey;
                return response;
            })

    }

    const chunkAPI = async (chunk: Blob, blockNumber: number) => {
        let data = new FormData();
        data.append('FormFile', chunk);
        data.append('FileName', fileNameUpload);
        data.append('FileSize', fileSize.toString());
        data.append('Path', finallist.join('/'));
        data.append('FileUploadType', "largefileupload");
        data.append('BlockNumber', blockNumber.toString());
        data.append('RowKey', rowKey.current);
        data.append('IsReplace', 'false');
        data.append('FileHash', fileHash);

        const apiConfig = {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}`, siteId }
        };
        // try {
        await axios.post(`${config.API_SERVICE}/FileManagement/DashboardStageChunks`, data, apiConfig)
            .then(response => {
                // Handle the response
                // console.log('Response:', response.data);
                rowKey.current = response.data.rowKey;
                return response;
            })
        // }
        // catch (error: any) {
        //     return error.response;
        // }
    }

    const resetFileUpload = async () => {
        setLoadingPage(true);
        if (!downloadInPRogress || showRetry) {
            try {
                await cancelUploadAPi();
                setShowReset(false);
                setPreventUplaod(false);
                let data = {
                    siteId,
                    page: 0,
                    pageSize: 10000,
                    parentPath: '/'
                }

                dispatch(gettabledata(data));
                resetChunkProperties();
            } catch (error) {
                setLoadingPage(false);
            }
        } else {
            // setShowRetry(false);
            resetChunkProperties();
            setShowReset(false);
            setLoadingPage(false);
            stopUpload.current = true;
        }
    }

    const commitChunkApi = async () => {
        var obj = {
            filename: fileNameUpload,
            fileuploadtype: 'largefileupload',
            filesize: fileSize,
            rowKey: rowKey.current
        }
        const apiConfig = {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}`, siteId }
        };
        await axios.post(`${config.API_SERVICE}/FileManagement/DashboardCommitChunks`, obj, apiConfig)
            .then(response => {
                // Handle the response
                // console.log('Response:', response.data);
                return response;
            })
    }

    const uploadFile = async () => {
        setPreventUplaod(true);
        setUploadValidateOpen(false);
        // console.log("uploadfile", preventUpload)
        setDownloadInPRogress(true);
        if (selectedLargeFile) {
            setShowReset(true);
            setShowRetry(false);
            setProgress(0);
            setShowProgress(true);
            // setShowUploadAndScanMsg(true);

            const totalChunks = chunkCount.current;

            for (let currentChunk = counter; currentChunk <= totalChunks; currentChunk++) {
                if (stopUpload.current) {

                    // resetChunkProperties();
                    await cancelUploadAPi();
                    setShowReset(false);
                    let data = {
                        siteId,
                        page: 0,
                        pageSize: 10000,
                        parentPath: '/'
                    }
                    dispatch(gettabledata(data));
                    resetChunkProperties();
                    break;
                }
                const startOfTheChunk = (currentChunk - 1) * chunkSize;
                const endOfTheChunk = startOfTheChunk + chunkSize;
                const chunk = selectedLargeFile.slice(startOfTheChunk, endOfTheChunk);
                try {
                    setCounter(currentChunk)
                    var chunkAPIResponse: any = await chunkAPI(chunk, currentChunk);
                    if (chunkAPIResponse && chunkAPIResponse.status === 400) {
                        setShowProgress(false);
                        setShowReset(false);
                        resetChunkProperties();
                        customToast('Already a file upload is in progress. Please try after sometime.', 'critical');
                        break;
                    }
                    var percentage = ((currentChunk) / chunkCount.current) * 100;
                    // console.log('chunk', currentChunk, totalChunks);
                    if (currentChunk === totalChunks) {
                        await commitChunkApi();
                        resetChunkProperties();
                        setProgress(100);
                        customToast(`${fileNameUpload} Uploaded!`, "success");
                        break;
                    }
                    setProgress(percentage);
                    // console.log('upload prgress %', progress, currentChunk, chunkCount.current, percentage);
                }
                catch (uploadError: any) {
                    console.log('upload error', retryCount, uploadError, showRetry);
                    if(uploadError?.response?.status === 507) {
                        setShowProgress(false);
                        setShowReset(false);
                        resetChunkProperties();
                        customToast(`File ${fileNameUpload} upload failed. Reason: Storage space exceeds the limit of ${blobSizeState?.message?.allocatedSize}`, "critical");
                        break;
                    } else if (retryCount <= 4 && uploadError?.response?.data != "File upload is already in progress!" && !_.includes(uploadError?.response?.data, 'character is more than 100')) {
                        // Retry logic
                        // console.log(`Retrying upload (attempt ${retryCount + 1})...`);
                        setShowRetry(true);
                        setRetryCount(retryCount + 1);
                        customToast(`${fileNameUpload} Upload Failed! Please Retry.`, "critical");
                        break
                    } else if (uploadError?.response?.data == "File upload is already in progress!") {
                        setShowProgress(false);
                        setShowReset(false);
                        resetChunkProperties();
                        customToast(`File upload is already in progress!`, "critical");
                        break;
                    } else if (_.includes(uploadError?.response?.data, 'character is more than 100')) {
                        setShowProgress(false);
                        setShowReset(false);
                        resetChunkProperties();
                        customToast(`Filename exceeded more than 100 character. Please rename the file.`, "critical");
                        break;
                    } else {
                        setShowRetry(false);
                        resetChunkProperties();
                        setShowReset(false);
                        stopUpload.current = true;
                        resetFileUpload();
                        customToast(`${fileNameUpload} Upload Failed!`, "critical");
                        break;
                    }
                }
            }
        }
    }

    const handleNewFileName = (e: any) => {
        if (e || e != null || e != undefined) {
            let name = e
            let regex = /[/\\?*:|"<>]/g;
            // console.log('regex', regex);
            if (!regex.test(name)) {
                setNewFileName(e)
            }
            setNewFileValidationMsg("")
            setOldFileValidationMsg("")
            setNewFileValidationLength("")
        } else {
            setNewFileValidationMsg("Please enter file name")
            setOldFileValidationMsg("")
            setNewFileValidationLength("")
        }
    }
    const convertDataMB = (val: any, type: any) => {
        let mbVal;
        if (type?.toLowerCase() === "gb") {
            mbVal = val * 1024
        }
        if (type?.toLowerCase() === "kb") {

            mbVal = val / 1024
        }
        if (type?.toLowerCase() === "bytes") {
            mbVal = val / 1024 / 1000
        } else {
            mbVal = val
        }
        return mbVal
    }
    //split decimal from character
    function splitFun(input: any) {
        const splitArray = input.match(/([\d\.]+)(.*)/);
        return {
            numeric: splitArray[1],
            nonnumeric: splitArray[2],
        };
    }
    const renameFileApiCallEvent = async () => {
        if (newFileName != null && newFileName != undefined) {
            if (newFileName?.length > 200) {
                setNewFileValidationLength("Length max is 200")
            }
            else if (newFileName === oldFileName) {
                setOldFileValidationMsg("Please change name to update it")
                setNewFileValidationMsg("")
            }
            else {
                setOpen(false)
                setLoadingPage(true)
                if (newFileName && selectedRowData[0]?.fileName != null) {
                    let final = finallist.join("/")
                    const data = {
                        container: selectedRowData[0]?.containerName ? selectedRowData[0].containerName : "",
                        fileOldName: selectedRowData[0]?.fileName ? selectedRowData[0]?.fileName : "",
                        fileNewName: renameFileExtension === ".undefined" || renameFileExtension === null ? "" : newFileName.trim() + renameFileExtension,
                        path: final,
                        rowKey: selectedRowData[0]?.rowKey ? selectedRowData[0]?.rowKey : "",
                        partitionKey: selectedRowData[0]?.partitionKey ? selectedRowData[0]?.partitionKey : "",
                        updatedBy: userEmail
                    }

                    let oldFileSizeMB;
                    if (oldFileType != "mb") {
                        oldFileSizeMB = await convertDataMB(oldFileSize, oldFileType)
                    } else {
                        oldFileSizeMB = oldFileSize
                    }
                    //Split charater from numeric data
                    const blobSizeRes = await splitFun(blobSize?.message?.consumedSize)
                    const allocatedSizeRes = await splitFun(blobSize?.message?.allocatedSize)
                    //End Split charater from numeric data
                    //Conver Data Into mb
                    const allocationDataConversionRes = convertDataMB(Number(allocatedSizeRes?.numeric), allocatedSizeRes?.nonnumeric)
                    const blobSizeMb = await convertDataMB(Number(blobSizeRes?.numeric), blobSizeRes?.nonnumeric)
                    let totalSize = Number(oldFileSizeMB) + Number(blobSizeMb)

                    // setuniqList([])
                    await dispatch(renameFileApiDashboard({ authorization: localStorage.getItem('access_token'), param: data, siteid: siteId }))
                }

                //Rename folder api call
                if (newFileName && !selectedRowData[0]?.fileName) {
                    const data = {
                        container: siteId,
                        updatedBy: userEmail,
                        oldFolder: selectedRowData[0]?.path,
                        newFolder: newFileName
                    }
                    // setuniqList([])
                    await dispatch(renameFolderApiDashboard({ authorization: localStorage.getItem('access_token'), param: data, siteid: siteId }))

                }
                else if (!selectedRowData[0]?.fileName || selectedRowData[0]?.fileName === null) {
                    setLoadingPage(false)
                    setOldFileValidationMsg('Name Required')
                }
                else if (!newFileName) {
                    setLoadingPage(false)
                    setNewFileValidationMsg('Name Required')
                }
                else {
                    setNewFileValidationMsg('')
                }
            }
        }

    }

    return (
        <>
        <HeaderComponent />
        <div className='extended-container'>
            {
                loading ?
                    <Loader overlayColor="#a0a0a0">
                        <div className="placeholder" />
                    </Loader>
                    : ""
            }
            <div className='bread-crumb-container'>
                <Breadcrumb>
                    <Breadcrumb.Item data-test='navigate-datamgmt' onClick={() => navigateToDashboard(orgNameVal)}>
                        Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => navigateToDashboard(orgNameVal)}>
                        {orgNameVal}
                    </Breadcrumb.Item>

                    <Breadcrumb.Item data-test='navigate-datamgmt' onClick={() => navigateToDatamanagement(xval)} > Data Management</Breadcrumb.Item>

                    {finallist?.map((item: any, index: any) => {
                        return (
                            <Breadcrumb.Item onClick={() => navigateToPreviousPage(item, xval, index)} >
                                {item}
                            </Breadcrumb.Item>)
                    })}
                </Breadcrumb>
            </div>
            {consumedBlobState > 0 && <div className='status-bar'>
                <span className='storage-text'>{'Storage space :'}</span>
                <div className='progress-dm'>
                    <Progress value={consumedBlobState} total={AllocatedSize} showProgress={false} size='xxsmall' />
                    <span className='htag'>{blobSizeState?.message?.consumedSize} of {blobSizeState?.message?.allocatedSize}</span>
                </div>
            </div>}
            <div style={{ border: '2px solid #303030', position: 'relative' }}>
                <div>
                    <div>
                        <DataManagementGrid
                            {...{
                                pathcount, finallist, paths, xval, newFileKey, inputEle,
                                newArr, userEmail, downloadSelectedRowData, selectedRowData,
                                selectedRow, click, isNewFolderBtnDisabled, showProgress, showReset, allowTableRowPerPage,
                                disablefuncupload, buttondisable, disablefunc,
                                buttondisabledownloadcrsr,
                                buttondisabledelete, buttondisabledownload,
                                pagecount, currentpage,

                                // Methods
                                setPathcount, setPaths, setSelectedRow, setSelectedRowData, setDownloadSelectedRowData,
                                setButtondisable, setButtondisabledownload, setButtondisabledownloadcrsr, setButtondisabledelete,
                                setDisablefunc, setDisablefuncUpload, setOldFileSize, setOldFileType, setAllowTableRowPerPage,
                                // setSelectAll,
                                setIsNewFolderBtnDisabled, setxval,
                                // reset
                                resetChunkProperties,
                                // setEnterKeyStorage,
                                setfileKey,
                                // setPathRenameFileSend,
                                handlechangelink,
                                setemptyFldrName, setFexist, dateFormatFun,
                                setNameLength,
                                // setEditInput, 
                                setNewArr,
                                setLoadingPage, setDeleteOpen, setNewFileName,
                                setOpen, setHideInput, setHideInputValMsg, setOldFileName, setRenameFileExtension,
                                eventBeforeUploadApi, refreshCurrentPageOfTable, setCurrentPage,
                                handlepagination,
                                // upload
                                uploadFile, progress, showRetry, resetFileUpload, fileNameUpload,
                                // search
                                setFilteredGridData, filteredGridData,
                                setSearchBoxValue, setIsSearchDataEmpty, searchBoxValue, isSearchDataEmpty,
                                // download
                                progressDownload, retryCountDownload, retryBtnState, fileNameVal, chunks, receivedLength, contentLength,
                                setProgressDownload,
                                setReceivedLength, setDownloadProgress, showDownloadProgress,
                                setChunks, setContentLength, hideRetryBtnState, setRetryCountDownload, setFileName, setDom, dom
                            }}
                        />

                        <DeleteFilePopup {...{ deletePopOpen, setDeleteOpen, handleDeleteApiFinalCall }} />
                        <FileExistPopup {...{ fexist, setFexist, filealreadyexistEvent }} />
                        <FileValidatePopup {...{ uploadValidateOpen, setUploadValidateOpen, uploadValidationPopupEvent, uploadFile }} />
                        <FolderNotEmptyPopup {...{ emptyFldrName, setemptyFldrName, folderNameCantBeEmptyEvent }} />
                        <NameLengthExceededPopup {...{ nameLength, setNameLength, nameLengthEvent }} />
                        <RenameFilePopup {...{ open, setOpen, hideInput, hideInputValMsg, newFileName, renameFileExtension, handleNewFileName, newFileValidationLength, newFileValidationMsg, oldFileValidationMsg, renameFileApiCallEvent }} />
                        <SymlinkErrorPopup {...{ symlinkError, setSymlinkError, handleSymlinkErrorPopup }} />
                    </div>
                </div>
            </div>
        </div></>
    )
};
