import { Button, Modal } from "@scuf/common"

export const FileValidatePopup = (props: any) => {
    const { uploadValidateOpen, setUploadValidateOpen, uploadValidationPopupEvent, uploadFile } = props;

    return (
        <Modal className='modal-content-dataManagement-wrapper' open={uploadValidateOpen} onClose={() => setUploadValidateOpen(false)} closeOnDimmerClick={false} closeOnDocumentClick={false}>
            <Modal.Content className='modal-content-dataManagement'>
                <span className='modal-text-dataManagement'>File already exists. Do you want to overwrite this?</span>
            </Modal.Content>
            <Modal.Footer className='modal-footer-dataManagement'>
                <Button type="secondary" size="small" content="Cancel" onClick={uploadValidationPopupEvent} className='cancel-secondary-dataManagement' />
                <Button type="primary" size="small" content="Yes" onClick={uploadFile} className='save-secondary-dataManagement' />
            </Modal.Footer>
        </Modal>
    )
}