import { useDispatch, useSelector } from 'react-redux';
import './css/plantDetail.scss'
import { Button, Icon, Modal } from '@scuf/common';
import { removeSelectedVM, setLoading, setSelectedView, getShutDownData, getRebootData, setLoggedIn, getDetails, getRestoreSnapshot, getCreateSnapshot, getStartData, setRebootStatus, setShutDownStatus, setRestoreSnapshot, setCreateSnapshot } from '../../../store/action';
import DeviceDetails from './deviceDetails';
import { useEffect, useState } from 'react';
import { AppState } from '../../../../common/store/combinedReducer';
import restoreSnap from  '../../../../common/assets/Restore Snapshot.png';
import restoreSnapEnable from  '../../../../common/assets/Restore fff.png';
import createSnap from  '../../../../common/assets/Create Snapshot 979797.png';
import createSnapEnable from  '../../../../common/assets/Create Snapshot.png';
import noVmIcon from '../../../../common/assets/noVmIcon.png';
import _ from 'lodash';
import { customToast } from '../../../../App';
import { REQUIRED_PERMISSIONS } from '../../../../common/authentication/config';

const PlantDetail = (props: any) => {
    const dispatch = useDispatch();
    const [clearAll, setClearAll] = useState(false);
    const [disableShutDown, setShutDown] = useState(true);
    const [disableRestart, setRestart] = useState(true);
    const [disableRestore, setRestoreSnapshotDisable] = useState(true);
    const [closeConfirmModal, setCloseConfirmModal] = useState(false);
    const [header, setHeader] = useState('');
    const [contentVal, setContent] = useState('');

    const orgData = props.orgData;
    const orgDataStatus = useSelector((state: AppState) => {
        return state.reducer.orgDataStatus;
    });
    const selectedVM = useSelector((state: AppState) => {
        return state.reducer.selectedVM;
    });
    const rebootStatus = useSelector((state: AppState) => {
        return state.reducer.rebootStatus;
    });
    const shutDownStatus = useSelector((state: AppState) => {
        return state.reducer.shutDownStatus;
    });
    const createSnapShotStatus = useSelector((state: AppState) => {
        return state.reducer.createSnapShotStatus;
    });
    const restoreSnapShotStatus = useSelector((state: AppState) => {
        return state.reducer.restoreSnapShotStatus;
    });
    const handleClearAll = () => {
        setClearAll(true);
        setShutDown(true);
        setRestart(true);
        setRestoreSnapshotDisable(true);
        orgData?.vmDetails?.forEach((element: any) => {
            element.checked = false;
        });
        dispatch(removeSelectedVM());
    }
    const addSelectedID = () => {
        const vmId: any = [];
        _.forEach(selectedVM, (data: any) => {
            if (data) {
                vmId.push(data.id)
            }
        });
        return vmId;
    }
    const getVmId = (status: any) => {
        const vm: any = [];
        _.forEach(selectedVM, (data: any) => {
            if (data.status === status) {
                vm.push(data.id)
            }
        });
        return _.uniq(vm);
    }

    const modal = () => {
        return (
            <Modal size='small' closeIcon={true} className='confirmAction'
                onClose={() => {
                    setCloseConfirmModal(false);
                    handleClearAll();
                }} open={closeConfirmModal} closeOnDimmerClick={false}>
                <Modal.Header>
                    {`Confirm ${header}`}
                </Modal.Header>
                <Modal.Content className='contentAction'>
                    {`Are you sure you want to ${contentVal} one or more Virtual Machines ?`}
                </Modal.Content>
                <Modal.Footer>
                    <Button className='cancel-secondary' type='secondary' size='small' content='No' onClick={() => {
                        setCloseConfirmModal(false);
                        handleClearAll();
                    }} />
                    <Button className='save-secondary' type='primary' size='small' content='Yes' onClick={() => {
                        setCloseConfirmModal(false);
                        if (header === 'Restore Snapshot') {
                            restoreSnapShot();
                        } else if (header === 'Create Snapshot') {
                            createSnapShot();
                        } else if (header === 'Shutdown') {
                            handleShutDownClick();
                        } else if (header === 'Restart') {
                            handleRestartClick();
                        }
                    }}/>
                </Modal.Footer>
            </Modal>
        )
    }

    const handleShutDownClick = () => {
        const vm = addSelectedID();
        dispatch(setLoading(true));
        dispatch(getShutDownData({ vmId: vm, authorization: localStorage.getItem('access_token'), siteId: orgData?.siteId }));
    }

    const handleRestartClick = () => {
        const vm = addSelectedID();
        dispatch(setLoading(true));
        if (_.every(selectedVM, ['status', 'POWERED_ON']) === true) {
            dispatch(getRebootData({ vmId: vm, authorization: localStorage.getItem('access_token'), siteId: orgData?.siteId }));
        }
        else if (_.every(selectedVM, ['status', 'POWERED_OFF']) === true) {
            dispatch(getStartData({ vmId: vm, authorization: localStorage.getItem('access_token'), siteId: orgData?.siteId }));
        }
        else {
            if (_.some(selectedVM, ['status', 'POWERED_ON']) === true) {
                const vmOff = getVmId('POWERED_ON');
                dispatch(getRebootData({ vmId: vmOff, authorization: localStorage.getItem('access_token'), siteId: orgData?.siteId }));
            }
            if (_.some(selectedVM, ['status', 'POWERED_OFF']) === true) {
                const vmOff = getVmId('POWERED_OFF');
                dispatch(getStartData({ vmId: vmOff, authorization: localStorage.getItem('access_token'), siteId: orgData?.siteId }));
            }
        }
    }

    const restoreSnapShot = () => {
        const vm = addSelectedID();
        dispatch(setLoading(true));
        dispatch(getRestoreSnapshot({ vmId: vm, authorization: localStorage.getItem('access_token'), siteId: orgData?.siteId }));
    }
    const createSnapShot = () => {
        const vm = addSelectedID();
        dispatch(setLoading(true));
        dispatch(getCreateSnapshot({ vmId: vm, authorization: localStorage.getItem('access_token'), siteId: orgData?.siteId }));
    }

    useEffect(() => {
        setClearAll(true);
        setShutDown(true);
        setRestart(true);
        setRestoreSnapshotDisable(true);
    }, [orgData]);

    useEffect(() => {
        if (shutDownStatus === 200 || shutDownStatus === 204 || shutDownStatus === 201) {
            dispatch(setLoading(false));
            handleClearAll();
            dispatch(setShutDownStatus(0));
        }
        if (rebootStatus === 200 || rebootStatus === 201 || rebootStatus === 204) {
            dispatch(setLoading(false));
            handleClearAll();
            dispatch(setRebootStatus(0));
        }
        if (restoreSnapShotStatus === 200 || restoreSnapShotStatus === 201 || restoreSnapShotStatus === 204) {
            dispatch(setLoading(false));
            handleClearAll();
            dispatch(setRestoreSnapshot(0));
        }
        if (createSnapShotStatus === 200 || createSnapShotStatus === 201 || createSnapShotStatus === 204) {
            dispatch(setLoading(false));
            handleClearAll();
            dispatch(setCreateSnapshot(0));
        }
        if (orgDataStatus !== 0) {
            dispatch(setLoading(false))
        }
    }, [orgDataStatus, shutDownStatus, rebootStatus, restoreSnapShotStatus, createSnapShotStatus]);


    return (
        <div data-testid='plant-detail-wrapper' className={REQUIRED_PERMISSIONS.SELF_SERVICE_INDIVIDUAL_VMS.every((p: string) =>
            props.orgData?.permissions?.includes(p)            
        ) ? 'plant-wrapper' : 'plant-wrapper-nopermission'}>
                {REQUIRED_PERMISSIONS.VIEW_CUSTOMER_PRIME_SPACE.every((p: string) =>
                            props.orgData?.permissions?.includes(p)            
                        ) ?
                    <>
                        {modal()}
                        {REQUIRED_PERMISSIONS.SELF_SERVICE_INDIVIDUAL_VMS.every((p: string) =>
                            props.orgData?.permissions?.includes(p)            
                        ) ?
                            <div className='button-group'>
                                <div className='button1-group'>
                                    <Button data-testid='plant-detail-restart' type='primary' disabled={disableRestart} className={disableRestart ? 'button1-disabled': 'button1-active'} iconRoot='global' icon='redo' content='Restart' iconPosition='left' size='small' onClick={() => {
                                         if (orgData && orgData.IsVCDAlive === false) {
                                            customToast('This site is not available currently, please try after sometime.', 'critical');
                                            handleClearAll();
                                        }
                                        else setCloseConfirmModal(true);
                                        setHeader('Restart');
                                        setContent('restart');
                                    }} />
                                    <Button data-testid='plant-detail-shutdown' type='primary' disabled={disableShutDown} className={disableShutDown ? 'button1-disabled': 'button1-active'} icon='power' content='Shutdown' iconPosition='left' size='small' onClick={() => {
                                         if (orgData && orgData.IsVCDAlive === false) {
                                            customToast('This site is not available currently, please try after sometime.', 'critical');
                                            handleClearAll();
                                        }
                                        else setCloseConfirmModal(true);
                                        setHeader('Shutdown');
                                        setContent('shutdown');
                                    }} />
                                    <Button data-testid='plant-detail-restore' type='primary'
                                    disabled={disableRestore} className={disableRestore ? 'button1-disabled': 'button1-active'} 
                                    icon={disableRestore? <img className='icon-restore' src={restoreSnap}/> : <img className='icon-restore' src={restoreSnapEnable}/>} content='&nbsp; Restore Snapshot' iconPosition='left' size='small' onClick={() => {
                                         if (orgData && orgData.IsVCDAlive === false) {
                                            customToast('This site is not available currently, please try after sometime.', 'critical');
                                            handleClearAll();
                                        }
                                        else setCloseConfirmModal(true);
                                        setHeader('Restore Snapshot');
                                        setContent('restore snapshot for');
                                    }}/>
                                    <Button data-testid='plant-detail-snapshot' type='primary' disabled={disableRestart} className={disableRestart ? 'button1-disabled': 'button1-active'} icon={disableRestart? <img className='icon-restore' src={createSnap}/> : <img className='icon-restore' src={createSnapEnable}/>} content='&nbsp; Create Snapshot' iconPosition='left' size='small' onClick={() => {
                                        if (orgData && orgData.IsVCDAlive === false) {
                                            customToast('This site is not available currently, please try after sometime.', 'critical');
                                            handleClearAll();
                                        }
                                        else setCloseConfirmModal(true);
                                        setHeader('Create Snapshot');
                                        setContent('create snapshot for');
                                    }}/>
                                </div>
                                <Button type='link' data-testid='plant-detail-clearall' className='button-clear' content='Clear All' onClick={() => handleClearAll()} />
                            </div> : null}
                        <div className='detail-outer-wrapper'>
                            <DeviceDetails
                                data-testid='plant-detail-device-details'
                                plantName={orgData?.siteId}
                                orgData={orgData}
                                vmDetails={orgData?.vmDetails}
                                clearAll={clearAll}
                                setClearAll={setClearAll}
                                disabled={disableRestart}
                                setRestart={setRestart}
                                shutDownClicked={disableShutDown}
                                setShutDown={setShutDown}
                                setRestoreSnapshot={setRestoreSnapshotDisable}
                            />
                        </div></> :
                        <span data-testid='plant-detail-no-data' className='no-data-available'>{ orgDataStatus !== 0 ?
                        <>
                            <img className='icon-restore' src={noVmIcon}/>
                            <span className='no-detail-text'>THIS SITE HAS NO AVAILABLE VIRTUAL MACHINE(S) FOR YOU</span>
                            <span className='no-detail-text'>Try selecting a different site</span>
                        </>
                        : null }</span>
                    }
            </div>
    )
};
export default PlantDetail;