import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../common/store/combinedReducer';
import HeaderComponent from '../header/header';
import './dashboard.scss';
import UserManagement from './LeftContainer/userManagement';
import DataManagement from './LeftContainer/data-management/dataManagement';
import LeftContainer from './LeftContainer/leftContainer';
import { getAssignedRoles, getCSRFToken, getDetails, getPermissions, getUserRoles, setCSRFToken, setLoading, setLoggedIn, setSelectedView } from '../../store/action';
import { useEffect, useState } from 'react';
import { Button, Loader, Modal } from '@scuf/common';
import { useNavigate } from 'react-router-dom';
import AuthProvider from '../../../common/authentication/authService';
import config from '../../../common/authentication/config';

export default function Dashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedView = useSelector((state: AppState) => {
        return state.reducer.selectedView;
    });
    const plantName = useSelector((state: AppState) => {
        return state.reducer.plantName;
    });
    const loading = useSelector((state: AppState) => {
        return state.reducer.loading;
    });
    const activePage = useSelector((state: AppState) => {
        return state.reducer.activePage;
    });
    const permissions = useSelector((state: AppState) => {
        return state.reducer.permissions;
    });
    const orgDataStatus = useSelector((state: AppState) => {
        return state.reducer.orgDataStatus;
    });
    const permissionStatus = useSelector((state: AppState) => {
        return state.reducer.permissionStatus;
    });
    const selectedPlant = useSelector((state: AppState) => {
        return state.reducer.selectedPlant;
    });
    const signalRConnection = useSelector((state: AppState) => {
        return state.reducer.signalR;
    });
    const csrf = useSelector((state: AppState) => {
        return state.reducer.csrf;
    });
    const csrfStatus = useSelector((state: AppState) => {
        return state.reducer.csrfStatus;
    });
    const [getRefreshToken, setRefreshtoken] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [isTabActive, setTabActive] = useState(true);
    const [intervalID, setIntervalID] = useState(0);

    let timer: any = 0;
    let popupTimer: any = 0;
    const refreshToken = () => {
        clearTimeout(intervalID);
        setClicked(true);
        popupTimer = 0;
        setRefreshtoken(false);
        // dispatch(setLoading(true));
        // handleRefresh();
    };

    // const getCSRFTokenRefresh = () => {
    //     dispatch(getCSRFToken({ authorization: localStorage.getItem('access_token') }));
    //     console.log('csrf tokenDetails');
    // }
    useEffect(() => {
        if (permissions?.length > 0 && localStorage.getItem('access_token') !== undefined && permissionStatus !== 500) {
            if (orgDataStatus === 0) {
                fetchDetails(selectedPlant);
            } else if (orgDataStatus !== 0) {
                dispatch(setLoggedIn(false));
            }
        } else if (permissionStatus !== 0 && (permissions === null || (permissions?.length <= 0 && localStorage.getItem('access_token') !== undefined) || permissionStatus === 500)) {
            dispatch(setLoggedIn(false));
            // console.log('permission expired');
            navigate('/loginFailed');
        }
    }, [permissions, orgDataStatus]);

    const fetchDetails = (orgs: any) => {
        dispatch(getDetails({ authorization: localStorage.getItem('access_token'), orgs }));
        dispatch(setLoggedIn(true));
    }

    useEffect(() => {
        // console.log('csrfStatus', csrfStatus, csrf)
        if (csrfStatus === 200 || csrfStatus !== 0) {
            const sub: any = AuthProvider().getUserId();
            dispatch(setLoading(false));
            dispatch(setCSRFToken({status: 0, data: false}));
            if (sub && csrf === true && permissions?.length === 0) {
                dispatch(getPermissions({ userSub: sub, authorization: localStorage.getItem('access_token') }));
            } else if (csrf === false) {
                // console.log('csrf expired');
                setTimeout(() => {
                    navigate('/loginFailed');
                }, 500)
            } else {
                dispatch(setLoading(false));
                // console.log('in else for csrf')
            }
        } else if (csrfStatus !== 200 && csrfStatus !== 0) {
            // console.log('csrf expired');
            setTimeout(() => {
                navigate('/loginFailed');
            }, 500)
        }
    }, [csrfStatus, csrf]);

    function handleRefresh(isRefreshed: boolean) {
        if (isRefreshed == true) AuthProvider().clearCookie('XSRF-TOKEN');
        AuthProvider().getRefreshToken()
            .then((resp: any) => {
                const tokenData = resp?.access_token;
                if (tokenData && resp && isRefreshed == true) {
                    dispatch(getCSRFToken({ authorization: localStorage.getItem('access_token') }));
                }
            })
    }
    useEffect(() => {
        if (localStorage.getItem('logged_user') === null || localStorage.getItem('logged_user') === JSON.stringify(false)) {
            navigate('/')
        }
        else {
            localStorage.setItem('logged_user', JSON.stringify(true));
            const selectedViewVal = { selectedView: 'Dashboard', plantName, activePage }
            dispatch(setSelectedView(selectedViewVal));
            if (permissions?.length === 0) {
                dispatch(setLoading(true));
                handleRefresh(true);
            }
        }
        window.setInterval(() => handleRefresh(false), 58 * 60 * 1000) // 58 mins
        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);
        window.addEventListener('storage', (e: any) => {
            if (e && e.key == 'logged_user') {
                navigate('/logout');
                window.close();
            }
        });
    }, []);
    const handleFocus = () => {
        localStorage.setItem('activeTab', selectedView)
        setTabActive(true);
        clearTimeout(timer);
    };

    const handleBlur = () => {
        setTabActive(false);
        console.log('timer started', selectedView);
        timer = setTimeout(() => {
            if (localStorage.getItem('activeTab') == selectedView) {
                setRefreshtoken(true);
            }
        }, config.INTERVAL); // 2hours
    };

    useEffect(() => {
        if (isTabActive === false) {
            if (getRefreshToken === true) {
                // console.log('timer in logout started', isTabActive, popupTimer, clicked);
                popupTimer = setTimeout(() => {
                    // if (clicked === false)
                    setRefreshtoken(false);
                    navigate('/logout');
                }, 60 * 1000);
                setIntervalID(popupTimer);
            }
        }
    }, [getRefreshToken, isTabActive]);

    const sessionExtendPopup = () => {
        return (
            <Modal className='userDeleteModal' closeIcon={false} open={getRefreshToken} closeOnDimmerClick={false} closeOnDocumentClick={false} >
                <Modal.Header className=''>You have been idle!</Modal.Header>
                <Modal.Content className='confirmModal'>
                    <span className='modal-text-vm'>You will logged out in 1 minute</span>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" size='small' content="Stay" className='save-secondary' onClick={refreshToken} />
                </Modal.Footer>
            </Modal>
        )
    }
    const renderViewSelected = () => {
        localStorage.setItem('activeTab', selectedView)
        console.log('selectedView', selectedView)
        switch (selectedView) {
            case 'UserManagement':
                navigate('/dashboard/userManagement');
                return;
                // return <UserManagement />
            case 'DataManagement':
                navigate('/dashboard/dataManagement');
                return;
                // return <DataManagement />
            case 'Dashboard':
            // default:
                return (
                    <>
                        <HeaderComponent />
                        <div className='dashboard-container'>
                            <div className='left-container'>
                                <LeftContainer />
                            </div>
                        </div>
                    </>
                )
        }
    }

    return (
        <div>
            {sessionExtendPopup()}
            {loading ?
                <Loader overlayColor='rgba(0,0,0,0.2)'>
                    <div className='placeholder' />
                </Loader> : null}
            {renderViewSelected()}
        </div>
    );
};
