import { Button, Icon, Loader, Modal } from "@scuf/common";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../common/store/combinedReducer";
import { useNavigate } from "react-router-dom";
import "./vmConsole.scss";
import _ from "lodash";
import { getCSRFToken, getWMKSDetails } from "../../store/action";
import { customToast } from "../../../App";
import AuthProvider from "../../../common/authentication/authService";
import config from "../../../common/authentication/config";

export default function VmConsole() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const wmksDetails = useSelector((state: AppState) => {
    return state.reducer.wmksDetails;
  });
  const wmksStatus = useSelector((state: AppState) => {
    return state.reducer.wmksStatus;
  });
  const [sessionReconnectPopup, setReconnect] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [isTabActive, setActiveTab] = useState(false);
  const [wmksVal, setWmks] = useState<any>();
  const [stateVal, setStateVal] = useState("");
  const [intervalID, setIntervalID] = useState(0);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const WMKS = window.WMKS;
  let wmks: any;
  let data: any;
  let res: any;
  let timer: any;
  let popupTimer: any;
  let copyDataVal: any;

  useEffect(() => {
    if (
      !localStorage.getItem("logged_user") ||
      localStorage.getItem("logged_user") === JSON.stringify(false)
    ) {
      navigate("/");
    }
    const lastPart = window.location.pathname?.split("/").pop();
    res = lastPart?.replace(/%20/g, " ");
    document.title = res;
    localStorage.setItem("activeTab", res);
    const val: any = localStorage.getItem(res);
    data = JSON.parse(val);
    if (data && data?.wmksData?.host) {
      handleVmWareConnection();
    }
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);
    window.addEventListener("storage", (e: any) => {
      if (e && e.key == "logged_user") {
        localStorage.removeItem(res);
        window.close();
      }
    });
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleFocus = () => {
    localStorage.setItem("activeTab", document.title);
    setActiveTab(true);
    clearTimeout(timer);
  };

  const handleBlur = () => {
    timer = setTimeout(() => {
      if (localStorage.getItem("activeTab") == document.title) {
        setReconnect(true);
      }
    }, config.INTERVAL); // 2 hours
    setActiveTab(false);
  };
  const refreshToken = () => {
    clearInterval(intervalID);
    setClicked(true);
    setReconnect(false);
  };

  const reconnectVM = () => {
    const lastPart = window.location.pathname?.split("/").pop();
    res = lastPart?.replace(/%20/g, " ");
    const val: any = localStorage.getItem(res);
    data = JSON.parse(val);
    console.log("isOnline", isOnline);
    if (data?.id !== "" && localStorage.getItem("activeTab") === res) {
      setStateVal("Reconnecting");
      dispatch(
        getWMKSDetails({
          id: data?.id,
          accessToken: localStorage.getItem("access_token"),
          siteId: data?.siteId,
        })
      );
    }
  };
  // close window if user does not respond to popup till 30sec
  useEffect(() => {
    if (isTabActive === false && clicked === false) {
      if (sessionReconnectPopup === true) {
        popupTimer = setTimeout(() => {
          // if (clicked === false)
          setReconnect(false);
          navigate("/logout");
        }, 60 * 1000);
        setIntervalID(popupTimer);
      }
    }
  }, [sessionReconnectPopup, isTabActive]);

  // get data and connect to wmks
  useEffect(() => {
    if (wmksStatus === 200) {
      const lastPart = window.location.pathname?.split("/").pop();
      res = lastPart?.replace(/%20/g, " ");
      const val: any = localStorage.getItem(res);
      data = JSON.parse(val);
      if (data && data?.wmksData?.host) {
        handleVmWareConnection();
      }
    } else if (wmksStatus !== 200 && wmksStatus !== 0) {
      // setLoading(false);
      setStateVal("disconnected");
      if (
        wmksStatus === 409 &&
        (wmksDetails && wmksDetails.minorErrorCode) === "VIM_INVALID_STATE"
      )
        customToast("VM in use. Please try after sometime", "critical");
      else if (
        wmksStatus === 409 &&
        wmksDetails &&
        wmksDetails.minorErrorCode === "INVALID_STATE"
      )
        customToast(
          `You cannot view the console since VM ${document.title} is not powered on. To view the console first refresh and power on the virtual machine`,
          "critical"
        );
      else if (wmksStatus === 500)
        customToast(
          "Error in connection. Please try after sometime",
          "critical"
        );
      else if (wmksStatus === 401 || wmksStatus === 403)
        customToast("Unauthorized to access the vm.", "critical");
      // setTimeout(() => {
      //   // close tab and remove the data after few sec to see the popup.
      //   localStorage.removeItem(res);
      //   wmksVal?.sendKeyCodes([WMKS.VSCAN_CODES.VSCAN_L],[WMKS.VSCAN_CODES.VSCAN_RGUI,WMKS.VSCAN_CODES.VSCAN_L]);
      //   window.close();
      // }, 10000)
    }
  }, [wmksDetails, wmksStatus]);

  const tokenPopup = () => {
    return (
      <Modal
        className="userDeleteModal"
        closeIcon={false}
        open={sessionReconnectPopup}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Modal.Header className="">You have been idle!</Modal.Header>
        <Modal.Content className="confirmModal">
          <span className="modal-text-vm">You will logged out in 1 minute</span>
        </Modal.Content>
        <Modal.Footer>
          <Button
            type="primary"
            size="small"
            content="Stay"
            className="save-secondary"
            onClick={refreshToken}
          />
        </Modal.Footer>
      </Modal>
    );
  };

  const handleVmWareConnection = () => {
    if (data?.wmksData && data?.wmksData?.host) {
      // setLoading(false);
      const host = data?.wmksData.host?.split(":");
      const url = `wss://${host[0]}/${data?.wmksData.port};${data?.wmksData.ticket}`;
      // @ts-ignore
      wmks = WMKS.createWMKS("wmksContainer", {})
        .register(
          WMKS.CONST.Events.CONNECTION_STATE_CHANGE,
          (event: any, value: any) => {
            setStateVal(value.state);
            if (value.state === WMKS.CONST.ConnectionState.CONNECTED) {
              // stateVal = 'Connected';
              $("#mainCanvas").on("focus", function () {
                wmks?.grab();
              });
              $("#mainCanvas").on("blur", function () {
                wmks?.ungrab();
              });
              handleConnection();
            }
            if (value.state === WMKS.CONST.ConnectionState.DISCONNECTED) {
              // stateVal = 'Disconnected';
              handleDisconnect();
            }
          }
        )
        .register(WMKS.CONST.Events.COPY, (evt: any, data: any) => {
          // here is the remote clipboard data:
          // console.log('copy data',evt, data);
          copyDataVal = data;
        });
      setWmks(wmks);
      wmks?.connect(url);
    } else {
    }
  };

  const handleConnection = () => {
    console.log("connect", wmks, WMKS);
    const size = wmksVal?.getRemoteScreenSize();
    wmksVal?.setRemoteScreenSize(size);
    wmksVal?.updateScreen();
    wmksVal?.setOption("changeResolution", true);
    wmksVal?.setOption("rescale", true);
  };
  const handleDisconnect = () => {
    wmksVal?.disconnect();
    reconnectVM();
  };
  const enterFullScreen = () => {
    const canFullScreen = wmksVal?.canFullScreen();
    // wmksVal?.sendKeyCodes([348], [348]);
    if (canFullScreen) {
      const size = wmksVal?.getRemoteScreenSize();
      wmksVal?.setRemoteScreenSize(size);
      wmksVal?.setOption("changeResolution", true);
      wmksVal?.setOption("rescale", true);
      wmksVal?.enterFullScreen();
      wmksVal?.updateScreen();
      // WMKS?.keyboardUtils.keyDownUpInfo();
    }
  };

  const callCAD = () => {
    wmksVal?.sendCAD();
  };

  const callToggleKeypad = () => {
    WMKS.extendedKeypad.prototype.toggleFunctionKeys();
  };

  return (
    <>
      {tokenPopup()}
      <>
        <div className="vmware-wrapper" id="wkms">
          <div className="header-vm">
            <div className="header-vm-right">
              <span className="vm-name">
                {document.title !== "Digital Prime Twin" ? document?.title : ""}
              </span>
              <button
                id="ctrl-alt-del"
                className="ctrBtn"
                data-i18n-key="wmks.ctrl.alt.del"
                onClick={() => callCAD()}
              >
                Ctrl+Alt+Del
              </button>
              {WMKS?.BROWSER.hasTouchInput() ? (
                <button
                  id="keypad"
                  disabled={!WMKS?.BROWSER.hasTouchInput()}
                  className="ctrBtn"
                  onClick={() => callToggleKeypad()}
                >
                  Toggle Keypad
                </button>
              ) : null}
              <span className="vm-name">
                {"Connection Status: " + stateVal}
              </span>
            </div>
            <div className="header-vm-left">
              {stateVal === "disconnected" ? (
                <button className="reconnectBtn" onClick={() => reconnectVM()}>
                  Reconnect
                </button>
              ) : null}
              <Icon
                root="common"
                name="enter-fullscreen"
                size="small"
                className="icon-left vm-fullscreen"
                onClick={() => enterFullScreen()}
              />
            </div>
          </div>
          <div id="wmksContainer" className="vm-wrapper"></div>
        </div>
      </>
    </>
  );
}
