import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

export const pkceCodeGenerator = () => {
    const length = 128;
    const possible = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~`;
    const base64URL = (text: any) => {
        return Base64.stringify(text).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
    }

    /**
     * This method will generate code verifier for PKCE
     */
    const generateCodeVerifier = () => {
        let verifier = '';
        for (let i = 0; i < length; i++) {
            verifier += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        const key = 'verifier';
        sessionStorage[key] = verifier;
        return verifier;
    }

    /**
     * This method will create code challenger for PKCE
     * @param verifier
     */
    const getCodeChallenge = () => {
        const verifier = generateCodeVerifier();
        const challenger = base64URL(sha256(verifier));
        return challenger;
    }

    /**
     * This method will return the code verifier and will delete the verfier
     */
    const getCodeVerifier = () => {
        const key = 'verifier';
        const verifier = sessionStorage[key];
        sessionStorage.removeItem('verifier');
        return verifier;
    }

    return {
        getCodeVerifier,
        getCodeChallenge
    };

}

export default pkceCodeGenerator;