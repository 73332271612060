import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import '@scuf/common/honeywell/theme.css'
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { compose, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { combineReducer } from './common/store/combinedReducer';
import { sagas } from './common/store/saga';
import { configureStore } from '@reduxjs/toolkit';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(
//   persistedReducer,
//   composeEnhancers(applyMiddleware(sagaMiddleware))
// );
const store = configureStore({
  reducer: combineReducer,
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(sagas);

const root = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
        <App />
        {/* </PersistGate> */}
      </Provider>
    </BrowserRouter>
   </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
