import { Button, Modal } from "@scuf/common"

export const FileExistPopup = (props: any) => {
    const { fexist, setFexist, filealreadyexistEvent } = props;

    return (
        <Modal className='modal-content-dataManagement-wrapper' open={fexist} onClose={() => setFexist(false)} closeOnDimmerClick={false} closeOnDocumentClick={false}>
            <span className='modal-text-dataManagement'>Folder already exists. Choose a different name.</span>
            <Modal.Footer className='modal-footer-dataManagement'>
                <Button type="secondary" size="small" content="Ok" onClick={filealreadyexistEvent} className='save-secondary-dataManagement' />
            </Modal.Footer>
        </Modal>
    )
}