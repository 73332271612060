import { Button, Modal } from "@scuf/common"

export const SymlinkErrorPopup = (props: any) => {
    const { symlinkError, setSymlinkError, handleSymlinkErrorPopup } = props;
    return (
        <Modal className='modal-content-dataManagement-wrapper' data-testid='modal' open={symlinkError} onClose={() => setSymlinkError(false)} closeOnDimmerClick={false} closeOnDocumentClick={false}>
            <span className='modal-text-dataManagement' style={{ color: 'red' }}>Soft links and hard links are not allowed.</span>
            <Modal.Footer className='modal-footer-dataManagement'>
                <Button type="secondary" size="small" content="Ok" onClick={handleSymlinkErrorPopup} className='save-secondary-dataManagement' />
            </Modal.Footer>
        </Modal>
    );
}