import './login.scss';
import { Button, Loader } from '@scuf/common';
import logo from '../../../common/assets/Honeywell_transparent.svg'
import AuthProvider from '../../../common/authentication/authService';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/action';
import { useEffect, useState } from 'react';

export default function Login() {
    const dispatch = useDispatch();
    const [pageLoaded, setPageLoaded] = useState(false);

    const handleSubmit = () => {
        dispatch(setLoading(true));
        AuthProvider().requestAuthCode();
    };

    useEffect(() => {
        // console.log('page loaded', document?.readyState);
        if (document.readyState === 'complete' || document.readyState === 'interactive') {
            setPageLoaded(true);
        }
    }, []);

    return (
        <div className='login-wrapper' data-testid='loginForm'>
            {!pageLoaded ?
                <Loader overlayColor='rgba(0,0,0,0.2)' data-testid='loader'>
                    <div className='placeholder' />
                </Loader> :
                <>
                    <img
                        src={logo}
                        loading={'eager'}
                        className='logo-wrapper' />
                    <span className='title-wrapper'>DIGITAL PRIME TWIN</span>
                    <p className='desc-wrapper'>Unlock the full potential of
                        your plant with Digital Prime Twin</p>
                    <Button
                        className='get-started'
                        actionType='submit'
                        content={'Get Started'}
                        size='small'
                        data-testid='loginButton'
                        onClick={handleSubmit}
                    />
                </>
            }
        </div>
    )
}