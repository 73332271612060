import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './header.scss';

const UserAssistance = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('logged_user') === null || localStorage.getItem('logged_user') === JSON.stringify(false)) {
            navigate('/')
        }
        window.addEventListener('storage', (e: any) => {
            if (e && e.key == 'logged_user') {
              window.close();
            }
          });
    }, []);

    return (
        <div className='userAssistance-wrapper'> 
           <iframe title='User Assistance' src='/userAssistance/Default_CSH.htm' width='100%'  height='100%'></iframe>
        </div>
    )
}

export default UserAssistance;