import { IState } from './model';

export const initialState: IState = {
    plantName: '', // org name
    selectedView : 'Dashboard',
    activePage: 0,
    url: '',
    orgData: [],
    wmksDetails: {},
    wmksStatus: 0,
    loading: false,
    userInfo: {},
    vmName: '',
    siteId: '',
    permissions: [],
    permissionStatus: 0,
    isLoggedIn: false,
    orgDataStatus: 0,
    selectedVM: [],
    shutDownStatus: 0,
    rebootStatus: 0,
    restoreSnapShotStatus: 0,
    createSnapShotStatus: 0,
    userRoles: [],
    userRolesStatus: 0,
    assignedRoles: [],
    assignedRolesStatus: 0,
    deleteResponse: [],
    modifyUserResponse: [],
    loadingUserRoles: false,
    newUserData: {},
    orgsDropDownData: [],
    selectedPlant: '',
    activeSignalR: false,
    hubConnection: null,
    selectedVMWare: {},
    tableData:[],
    uploadfile:{},
    deleteResponseDashboard: [],
    deleteDashboardRes:[],
    downloadDashboardSelectRow:[],
    blobSizeDashboard:{},
    setDownloadRes:[],
    renameRes:[],
    renameFolderRes:[],
    uploadValidationRes:[],
    chunkUploadRes: [],
    csrf: '',
    csrfStatus: 0,
    signalR: {}
}
// permissions
// [
//     "dp.common.viewprimespace", // view dashboard
//     "dp.vm.access", // vm access , edit vm
//     "dp.vm.selfservice", // buttons n checkbox
//     "dp.user.management", // show user management
//     "dp.common.linkotherapps",
//     "dp.common.filetransfer",
//     "dp.site.datamanagement", // display data mgmt button n redirect
//     "dp.site.manage",
//     "dp.customeradmin.manage"
//   ]

// [
//     {
//         id: 'urn:vcloud:org:3d0ef02b-097b-49b2-8f04-fee046db4913',
//         name: 'DP_1',
//         displayName: 'Sonam_DP_1',
//         description: 'Test Org Digital Prime_1 Sonam',
//         isEnabled: true,
//         index: 1,
//         vmDetails: [
//           {
//             id: 'vm-9b1c224b-c44d-4d38-b0b1-1b04e444cf65',
//             name: 'TestVM2',
//             description: null,
//             status: 'UNKNOWN',
//             hardwareVersion: 0,
//             snapshot: false,
//             snapshotCreated: null,
//             checked: false
//           },
//           {
//             id: 'vm-f8a27a77-071e-42ed-8d5f-b9dbbe6ff680',
//             name: 'VM-EPKSDC-0',
//             description: null,
//             status: 'UNKNOWN',
//             hardwareVersion: 0,
//             snapshot: false,
//             snapshotCreated: null,
//             checked: false
//           },
//           {
//             id: 'vm-bb5568e4-b603-47bb-a6b5-ac327fa4d073',
//             name: 'Test VM',
//             description: null,
//             status: 'UNKNOWN',
//             hardwareVersion: 0,
//             snapshot: false,
//             snapshotCreated: null,
//             checked: false
//           }
//         ],
//         permissions: [
//           'dp.common.viewprimespace',
//           'dp.vm.selfservice',
//           'dp.user.management',
//           'dp.common.linkotherapps',
//           'dp.site.datamanagement',
//           'dp.vm.access',
//           'dp.common.filetransfer'
//         ]
//       },
//       {
//         id: 'urn:vcloud:org:3d0ef02b-097b-49b2-8f04-fee046db4922',
//         name: 'DP_2',
//         displayName: 'Sonam_DP_2',
//         description: 'Test Org Digital Prime_2 Sonam',
//         index: 2,
//         isEnabled: true,
//         vmDetails: [
//           {
//             id: 'vm-9b1c224b-c44d-4d38-b0b1-1b04e444cf44',
//             name: 'TestVM12',
//             description: null,
//             status: 'UNKNOWN',
//             hardwareVersion: 0,
//             snapshot: false,
//             snapshotCreated: null,
//             checked: false
//           },
//           {
//             id: 'vm-f8a27a77-071e-42ed-8d5f-b9dbbe6ff622',
//             name: 'VM-EPKSDC-12',
//             description: null,
//             status: 'UNKNOWN',
//             hardwareVersion: 0,
//             snapshot: false,
//             snapshotCreated: null,
//             checked: false
//           },
//           {
//             id: 'vm-bb5568e4-b603-47bb-a6b5-ac327fa4d044',
//             name: 'Test VM 12',
//             description: null,
//             status: 'UNKNOWN',
//             hardwareVersion: 0,
//             snapshot: false,
//             snapshotCreated: null,
//             checked: false
//           }
//         ],
//         permissions: [
//           'dp.common.viewprimespace',
//           'dp.vm.selfservice',
//           'dp.user.management',
//           'dp.common.linkotherapps',
//           'dp.site.datamanagement',
//           'dp.vm.access',
//           'dp.common.filetransfer'
//         ]
//       }
// ]