import './login.scss';
import { Button } from '@scuf/common';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/action';
import { useNavigate } from 'react-router-dom';
import HeaderComponent from '../header/header';
import noPermission from '../../../common/assets/loginError.png';

export default function RedirectLogin() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = () => {
        dispatch(setLoading(true));
        navigate('/logout');
        localStorage.setItem('logged_user', JSON.stringify(false));
    };

    return (
        <div className='no-permission-wrapper'>
            <HeaderComponent />
            <div className='no-permission-layout'>
                <div className='no-permission-wrap'>
                    <img className='icon-restore' src={noPermission}/>
                    <span className='no-permission-text'>SOMETHING WENT WRONG, PLEASE CONTACT ADMINISTRATOR.</span>
                    <span className='no-permission-text-smaller'>You do not have access to the available sites</span>
                    <Button
                        className='no-permission-button'
                        actionType='submit'
                        content={'Go to Home'}
                        size='small'
                        data-testid='goHome'
                        textTransform={false}
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </div>
        )
}