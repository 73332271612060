import { useState, useEffect, Suspense, ReactFragment } from 'react';
import {
  LocalizationConfig,
  LocalizationProvider,
  TranslationConsumer,
} from '@scuf/localization';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { routesConfig } from './routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Notification, Button } from '@scuf/common';

interface IMessageDescriptor {
  id: string;
  defaultMessage: string;
}

export interface ITranslationObject {
  localizations: {};
}

export declare type TFunction = (
  key: string | IMessageDescriptor,
  option?: Record<string, any>
) => string;

export let notifyToast: (
  content: string | JSX.Element,
  type: 'warning' | 'success' | 'error' | 'info'
) => void;

export let translateString: (value: string) => string | JSX.Element;

export const importLocals = (language: string) =>
  import(`./localization/localization_${language}`).catch(
    () => import('./localization/localization_enus')
  );

export const ToastNotification = ({ closeToast, text, type }: any) => (
  <Notification
      className='toast-notification'
      severity={type}
      onCloseClick={closeToast}
      hasIcon={false}
      title={text}
  />
);
export let customToast = (
  content: string | JSX.Element,
  type: 'critical' | 'important' | 'information' | 'success') => {
  toast(<ToastNotification  closeToast={true} text={content} type={type}/>);
};

function App(props: any) {
  const [views, setViews] = useState<ReactFragment>([]);

  translateString = (value: string) => (
    <TranslationConsumer>
      {(translate: TFunction) => translate(value)}
    </TranslationConsumer>
  );
  const culture = (document.getElementById('userOptCulture') as HTMLInputElement)?.value.toLowerCase();
  const systemLanguage = culture
    ? culture
    : 'enus';

    useEffect(() => {
      const loadTranslations = async () => {
        const language =
          systemLanguage !== 'null' ? systemLanguage.replace('-', '') : 'enus';
        const translationString: ITranslationObject = await importLocals(
          language
        );
        const renderHealthdashboardView = renderHealthDasboard(
          language,
          translationString.localizations
        );
        Promise.resolve(renderHealthdashboardView).then(setViews);
      };
      loadTranslations();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // useEffect(() => {
    //   if (localStorage.getItem('logged_user') === 'false') {
    //     props.persistor?.pause();
    //     props.persistor?.flush().then(() => {
    //       return props.persistor?.purge();
    //     });
    //   }
    // }, [localStorage.getItem('logged_user')])
    const renderHealthDasboard = (lang: string, trans: any) => (
      <div>
        <LocalizationConfig languageCode={lang}>
          <LocalizationProvider translations={trans}>
            {/* <ToastProvider> */}
              <RenderTosterProvider />
              <ToastContainer
                toastClassName='toast-notification-wrap'
                autoClose={false}
                position='top-right'
                hideProgressBar={true}
                draggable={false}
                closeButton={false}
                icon={false}
                // toastClassName='toast-notification-wrap'
              />
            {/* </ToastProvider> */}
          </LocalizationProvider>
        </LocalizationConfig>
      </div>
    );

  return (
    <Suspense fallback='Loading Local Files...'>
      <div>{views}</div>
    </Suspense>
  );
}

export const RenderTosterProvider = () => {
  // const { addToast } = useToasts();
  // customToast = (
  //   content: string | ReactFragment,
  //   type: 'warning' | 'success' | 'error' | 'info'
  // ) =>
  //   addToast(content, {
  //     appearance: type,
  //     autoDismiss: true,
  //   });
  return (
    <Routes>
       {routesConfig?.map(({...props}, index) => (
        <Route key={index} Component={props.component} element={props.element} path={props.path}>
        </Route>
      ))}
    </Routes>
  );
};

export default App;
