import axios from 'axios';
import { ADD_SEARCH_USER_API, CREATE_SNAPSHOT_API, DASHBOARD_BLOB_SIZE_API, DASHBOARD_DOWNLOAD_RECORDS_API, DASHBOARD_FILE_RENAME_API, DASHBOARD_FOLDER_RENAME_API, DASHBOARD_UPLOAD_VALIDATION_API, DELETE_USER_API, GET_ASSIGNEDROLES_API, GET_DATA_API, GET_PERMISSION_API, GET_ROLES_API, GET_TABLE_DATA_API, GET_WMKS_API, GET_XTOKEN, REBOOT_API, RESTORE_SNAPSHOT_API, SHUT_DOWN_API, START_API, TABLE_DATA_DELETE_API, UPDATE_USER_API, UPLOAD_CHUNK_API, UPLOAD_FILE_API } from '../../common/apiEndPoint/apiEndPoint';
import AuthProvider from '../../common/authentication/authService';
import { customToast } from '../../App';

// Add a request interceptor
axios.interceptors.request.use(
  async (config) => {
    if(config.url !== undefined) {
      let str = config.url;
      if(str.search('forge') < 0) {
        const token = await AuthProvider().getAccessToken();
        const xToken = await AuthProvider().getCookie('XSRF-TOKEN');
        if (token && xToken) {
          config.headers['Authorization'] = 'Bearer ' + token;
          config.headers['X-XSRF-TOKEN'] = xToken;
        }
      }      
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
);
axios.interceptors.response.use(response => response, async err => {
  const status = err.response?.status;
  const xToken = await AuthProvider().getCookie('XSRF-TOKEN');
  if (status === 400 && err.response?.statusText == 'Bad Request' && (xToken == '' || xToken == undefined)) {
      // if (AuthProvider().isAuthenticated() === false) {
      //   await AuthProvider().getRefreshToken();
      // }
      const token = await AuthProvider().getAccessToken();
      await axios.get(`${GET_XTOKEN}`, {
        headers: {
          'Authorization': 'Bearer ' + token, // the token is a variable which holds the token
        }
      });
      return axios(err.response.config)
  } else if (status === 401 && err.response?.statusText == 'Unauthorized') {
    if (AuthProvider().isAuthenticated() === false) {
      await AuthProvider().getRefreshToken();
    }
    return axios(err.response.config)
  }

  return Promise.reject(err)
})

export const cookieVal = () => {
  const cookie = AuthProvider().getCookie('XSRF-TOKEN');
  if (cookie !== '') {
    return cookie;
  }
}
export const getCSRFAPI = async (requestParams: any) => {
  try {
    const res: any = await axios.get(`${GET_XTOKEN}`, {
      headers: {
        'Authorization': `Bearer ${requestParams?.authorization}`, // the token is a variable which holds the token
      }
    });
    return res;
  } catch (error) {
    return error;
  }
}
export const getVMDetailsAPI = async (requestParams: any) => {
  try {
    const res: any = await axios.get(GET_DATA_API, {
      headers: {
        'Accept': 'application/json;version=36.3',
        'Authorization': `Bearer ${requestParams?.authorization}`, // the token is a variable which holds the token
        'siteId': requestParams?.orgs,
        'X-XSRF-TOKEN': cookieVal()
      }
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getWMKSDataAPI = async (requestParams: any) => {
  try {
    const res: any = await axios.post(`${GET_WMKS_API}?VMId=${requestParams?.id}`,'',
      {
        headers: {
          'Authorization': `Bearer ${requestParams?.accessToken}`, // the token is a variable which holds the token
          'siteId': requestParams?.siteId,
          'X-XSRF-TOKEN': cookieVal()
        }
      });
    return res;
  } catch (error) {
    return error;
  }
}

export const getPermissionAPI = async (requestParams: any) => {
  try {
    const res: any = await axios.get(`${GET_PERMISSION_API}`, // change the url for permission
      {
        headers: {
          'User': requestParams?.userSub || '',
          'Authorization': `Bearer ${requestParams?.authorization}`,
          'X-XSRF-TOKEN': cookieVal()
        }
      });
    return res;
  } catch (error) {
    return error;
  }
}
export const getShutDownAPI = async (requestParams: any) => {
  try {
    const vmIds: any = requestParams?.vmId?.toString() || '';
    const res: any = await axios.post(`${SHUT_DOWN_API}`, '', // change the url for permission
      {
        headers: {
          'vmID': vmIds,
          'Authorization': `Bearer ${requestParams?.authorization}`,
          'siteId': requestParams?.siteId,
          'X-XSRF-TOKEN': cookieVal()
        }
      });
    return res;
  } catch (error) {
    return error;
  }
}
export const getRebootAPI = async (requestParams: any) => {
  try {
    const vmIds: any = requestParams?.vmId?.toString() || '';
    const res: any = await axios.post(`${REBOOT_API}`, '', // change the url for permission
      {
        headers: {
          'vmID': vmIds,
          'Authorization': `Bearer ${requestParams?.authorization}`,
          'siteId': requestParams?.siteId,
          'X-XSRF-TOKEN': cookieVal()
        }
      });
    return res;
  } catch (error) {
    return error;
  }
}
export const getStartAPI = async (requestParams: any) => {
  try {
    const vmIds: any = requestParams?.vmId?.toString() || '';
    const res: any = await axios.post(`${START_API}`, '', // change the url for permission
      {
        headers: {
          'vmID': vmIds,
          'Authorization': `Bearer ${requestParams?.authorization}`,
          'siteId': requestParams?.siteId,
          'X-XSRF-TOKEN': cookieVal()
        }
      });
    return res;
  } catch (error) {
    return error;
  }
}
export const getCreateSnapshotAPI = async (requestParams: any) => {
  try {
    const vmIds: any = requestParams?.vmId?.toString() || '';
    const res: any = await axios.post(`${CREATE_SNAPSHOT_API}`, '', // change the url for permission
      {
        headers: {
          'vmID': vmIds,
          'Authorization': `Bearer ${requestParams?.authorization}`,
          'siteId': requestParams?.siteId,
          'X-XSRF-TOKEN': cookieVal()
        }
      });
    return res;
  } catch (error) {
    return error;
  }
}
export const getRestoreSnapShotAPI = async (requestParams: any) => {
  try {
    const vmIds: any = requestParams?.vmId?.toString() || '';
    const res: any = await axios.post(`${RESTORE_SNAPSHOT_API}`, '', // change the url for permission
      {
        headers: {
          'vmID': vmIds,
          'Authorization': `Bearer ${requestParams?.authorization}`,
          'siteId': requestParams?.siteId,
          'X-XSRF-TOKEN': cookieVal()
        }
      });
    return res;
  } catch (error) {
    return error;
  }
}
export const getUserRolesAPI = async (requestParams: any) => {
  try {
    const res: any = await axios.get(`${GET_ROLES_API}`, // change the url for permission
      {
        headers: {
          'Authorization': `Bearer ${requestParams?.authorization}`,
          'siteId': requestParams?.siteId,
          'X-XSRF-TOKEN': cookieVal()
        }
      });
    return res;
  } catch (error) {
    return error;
  }
}
export const getAssignedRolesAPI = async (requestParams: any) => {
  try {
    const res: any = await axios.get(`${GET_ASSIGNEDROLES_API}`, // change the url for permission
      {
        headers: {
          'Authorization': `Bearer ${requestParams?.authorization}`,
          'siteId': requestParams?.siteId,
          'X-XSRF-TOKEN': cookieVal()
        }
      });
    return res;
  } catch (error) {
    return error;
  }
}
export const getUserDeleteAPI = async (requestParams: any) => {
  try {
    const res: any = await axios.delete(`${DELETE_USER_API}`, // change the url for permission
      {
        data: requestParams?.deleteUsers,
        headers: {
          'Authorization': `Bearer ${requestParams?.authorization}`,
          'siteId': requestParams?.siteId,
          'userId': requestParams?.userId,
          'X-XSRF-TOKEN': cookieVal()
        }
      });
    return res;
  } catch (error) {
    return error;
  }
}
export const getNewUserAPI = async (requestParams: any) => {
  try {
    const res: any = await axios.post(`${ADD_SEARCH_USER_API}`, requestParams?.data,// change the url for permission
      {
        headers: {
          'Authorization': `Bearer ${requestParams?.authorization}`,
          'siteId': requestParams?.siteId,
          'X-XSRF-TOKEN': cookieVal()
        }
      });
    return res;
  } catch (error) {
    return error;
  }
}
export const getUpdateRoleUserAPI = async (requestParams: any) => {
  try {
    const res: any = await axios.put(`${UPDATE_USER_API}`, requestParams?.data,// change the url for permission
      {
        headers: {
          'Authorization': `Bearer ${requestParams?.authorization}`,
          'siteId': requestParams?.siteId,
          'userId': requestParams?.userId,
          'X-XSRF-TOKEN': cookieVal()
        }
      });
    return res;
  } catch (error) {
    return error;
  }
}
//data
export const gettabledataAPI = async (requestParams: any) => {
  try {
    // const res=await axios.get(`${GET_TABLE_DATA_API}?container=${requestParams.container}`,
    const res = await axios.get(`${GET_TABLE_DATA_API}?siteId=${requestParams?.siteId}&page=${requestParams?.page}&pageSize=${requestParams?.pageSize}&parentPath=${requestParams?.parentPath}`,
      {
        headers: {
          'Accept': 'application/json;version=36.3',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'siteId': requestParams?.siteId,
          'X-XSRF-TOKEN': cookieVal()
        }
      });
    // console.log("getservice", res)
    return res;

  } catch (error) { }
};
export const getUploadAPI = async (requestParams: any) => {
    // console.log("UPLOAD_FILE_API", UPLOAD_FILE_API)
    if (requestParams != null && requestParams != undefined) {
        try {
            // console.log('get upload api - started', 'reqParam', requestParams);
            // debugger
            const res = await axios.post(`${UPLOAD_FILE_API}`, requestParams?.data,// change the url for permission
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'siteId': requestParams?.siteid,
                        'X-XSRF-TOKEN': cookieVal()
                    },

                });

            return res.data;
        } catch (error) {
            console.log("error", error)
            return error;
        }
    }
}

//New
// export const getDeleteTableDataAPI = async (requestParams: any) => {
//   try {
//     const res = await axios.post(`${TABLE_DATA_DELETE_API}`, requestParams.param,
//       {
//         headers: {
//           'Authorization': `Bearer ${requestParams.authorization}`,
//           'siteId': requestParams?.siteid,
//           'X-XSRF-TOKEN': cookieVal()
//         }
//       });
//     //  console.log('request string', requestParams, res.data)
//     return res;
//   } catch (error) {
//     return error;
//   }
// }
export const getDeleteTableDataAPI = async (requestParams: any) => {
    if (requestParams != null && requestParams != undefined) {
        try {
            const res = await axios.delete(`${TABLE_DATA_DELETE_API}`, {
                headers: {
                    'Authorization': `Bearer ${requestParams.authorization}`,
                    'siteId': requestParams?.siteid,
                    'X-XSRF-TOKEN': cookieVal()
                },
                data: requestParams.param
            });
            //  console.log('request string', requestParams, res.data)
            return res;
        } catch (error) {
            return error;
        }
    }
}

export const fileRenameDashboardApi = async (requestParams: any) => {
    if (requestParams != null && requestParams != undefined) {
        try {
            // console.log('file rename dashboard api', requestParams, RENAME_FILE_DATA);
            // debugger
            if (requestParams != null && requestParams != undefined) {
                const res = await axios.post(`${DASHBOARD_FILE_RENAME_API}`, requestParams.param,// change the url for permission
                    {
                        headers: {
                            'Authorization': `Bearer ${requestParams.authorization}`,
                            'siteId': requestParams?.siteid,
                            'X-XSRF-TOKEN': cookieVal()
                        },
                    });
                return res;
            }
            // return
        } catch (error) {
            return error;
        }
    }
}
export const folderRenameDashboardApi = async (requestParams: any) => {
    if (requestParams != null && requestParams != undefined) {
        try {
            // console.log('file rename dashboard api', requestParams, RENAME_FILE_DATA);
            // debugger
            const res = await axios.post(`${DASHBOARD_FOLDER_RENAME_API}`, requestParams.param,// change the url for permission
                {
                    headers: {
                        'Authorization': `Bearer ${requestParams.authorization}`,
                        'siteId': requestParams?.siteid,
                        'X-XSRF-TOKEN': cookieVal()
                    },
                });
            return res;
            // return
        } catch (error) {
            return error;
        }
    }
}
export const getDashboardBlobDataApi = async (requestParams: any) => {
    if (requestParams != null && requestParams != undefined) {
        try {
            // console.log("Blob get api", requestParams)

            const res = await axios.get(`${DASHBOARD_BLOB_SIZE_API}?container=${requestParams?.container}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'siteId': requestParams?.container,
                    'X-XSRF-TOKEN': cookieVal()
                }
            });

            return res;
        } catch (error) { }
    }
};
//Upload Validation Api
export const dashboardUploadValidation = async (requestParams: any) => {
    if (requestParams != null && requestParams != undefined) {
        try {
            const res = await axios.get(`${DASHBOARD_UPLOAD_VALIDATION_API}?filename=${requestParams.params.filename}&parentPath=${requestParams.params.parentPath}`, {
                headers: {
                    'Authorization': `Bearer ${requestParams.authorization}`,
                    'siteId': requestParams?.siteid,
                    'X-XSRF-TOKEN': cookieVal()
                }
            });

            return res;
        } catch (error) { }
    }
};

export const uploadChunk = async (requestParams: any ) => {
  try {
    const res = await axios.post(`${UPLOAD_CHUNK_API}`, requestParams.param, {
      headers: {
        'Authorization': `Bearer ${requestParams.authorization}`,
        'siteId':requestParams.siteid,
        'X-XSRF-TOKEN': cookieVal()
      }
    });

    return res;
  } catch (error) { return error }
}
