import { Button, Modal } from "@scuf/common"

export const FolderNotEmptyPopup = (props: any) => {
    const { emptyFldrName, setemptyFldrName, folderNameCantBeEmptyEvent } = props;

    return (
        <Modal className='modal-content-dataManagement-wrapper' open={emptyFldrName} onClose={() => setemptyFldrName(false)} closeOnDimmerClick={false} closeOnDocumentClick={false}>
            <span className='modal-text-dataManagement'>Folder name can not be empty.</span>
            <Modal.Footer className='modal-footer-dataManagement'>
                <Button type="secondary" size="small" content="Ok" onClick={folderNameCantBeEmptyEvent} className='save-secondary-dataManagement' />
            </Modal.Footer>
        </Modal>
    )
}