import axios, { AxiosRequestConfig } from 'axios';
import queryString from 'query-string';
import jwt_decode from 'jwt-decode';
import config from './config';
import pkceCodeGenerator from './pkce-code-generator';

export interface IUserInfo {
    email: string;
    name: string;
    given_name: string;
    family_name: string;
}

function AuthProvider() {
    const pkce = pkceCodeGenerator();
    const isAuthenticated = () => {
        const key = 'access_token';
        const token = localStorage[key];
        if (token) {
            const decodedToken: any = jwt_decode(token);
            const now = Date.now().valueOf() / 1000;
            if (typeof decodedToken.exp !== 'undefined' && decodedToken.exp < now) {
                clearStorage();
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    const getCookie = (cookieName: string) => {
        const name = cookieName + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    const clearCookie = (cookieName: string) => {
        document.cookie = cookieName + "=;expires=" + new Date().toUTCString();
    }
    const requestAuthCode = () => {
        const stateValue = crypto?.getRandomValues(new Uint32Array(1))[0];
        const challenge = pkce.getCodeChallenge();
        const data = {
            response_type: 'code',
            client_id: config.CLIENT_ID,
            redirect_uri: config.REDIRECT_URI,
            scope: 'groups offline openid builder.access',
            state: stateValue,
            code_challenge: challenge,
            code_challenge_method: 'S256'
        }
        const params = queryString.stringify(data);
        const authorizationURL = `${config.BASE_URL}/oauth2/auth?${params}`;
        window.location.href = authorizationURL;
    }

    const acquireAccessToken = async (authCode: string) => {
        const options: AxiosRequestConfig = {
            method: 'POST',
            url: `${config.BASE_URL}/oauth2/token`,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: queryString.stringify({
                grant_type: 'authorization_code',
                code: authCode,
                redirect_uri: config.REDIRECT_URI,
                client_id: config.CLIENT_ID,
                code_verifier: pkce.getCodeVerifier()
            }),
        };
        try {
            const response = await axios(options);
            const responseOK = response && response.status === 200;
            if (responseOK) {
                const tokenDetails = await response.data;
                if (tokenDetails && Object.keys(tokenDetails).length > 0) {
                    for (const t in tokenDetails) {
                        localStorage[t] = tokenDetails[t];
                    }
                    localStorage.setItem('token-generated', encodeURIComponent('' + new Date()));
                }
                return tokenDetails;
            }
            else {}
        }
        catch (err) {}
    }

    const getAccessToken = () => {
        const key = 'access_token';
        if (isAuthenticated()) {
            return localStorage[key];
        }
        return null;
    }

    const getIdToken = () => {
        const key = 'id_token';
        if (isAuthenticated()) {
            return localStorage[key];
        }
        return null;
    }

    const getUserId = () => {
        const key = 'access_token';
        const token = localStorage[key];
        if (token) {
            const decodedToken: any = jwt_decode(token);
            return decodedToken.sub;
        }
    }

    const getUserInfo = async () => {
        if (isAuthenticated() === false)
            return null;
        try {
            const key = 'access_token';
            const options: AxiosRequestConfig = {
                method: 'GET',
                url: `${config.BASE_URL}/idp/userinfo.openid`,
                headers: { 'Authorization': `Bearer ${localStorage[key]}`},
            };
            const response = await axios(options);
            const responseOK = response && response.status === 200;
            if (responseOK) {
                return await response.data;
            } else {
            }
        } catch (e) {}
        return null;
    }

    const getLoggedInUserInfo = () => {
        const status = isAuthenticated();
        const key = 'id_token';
        if (status) {
            const token = localStorage[key];
            if (token) {
                const decoded = jwt_decode(token);
                return decoded;
            }
        }
        return null;
    }

    const getRefreshToken = async () => {
        if (isAuthenticated() === false) {
            goToLoginPage();
        } else {
            const key = 'refresh_token';
            const options: AxiosRequestConfig = {
                method: 'POST',
                url: `${config.BASE_URL}/oauth2/token`,
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: queryString.stringify({
                    grant_type: 'refresh_token',
                    refresh_token: `${localStorage[key]}`,
                    redirect_uri: config.REDIRECT_URI,
                    client_id: config.CLIENT_ID
                }),
            };
            try {
                const response = await axios(options);
                const responseOK = response && response.status === 200;
                if (responseOK) {
                    const tokenDetails = await response.data;
                    if (tokenDetails && Object.keys(tokenDetails).length > 0) {
                        for (const t in tokenDetails) {
                            localStorage[t] = tokenDetails[t];
                        }
                        localStorage.setItem('token-generated', encodeURIComponent('' + new Date()));
                    }
                    return tokenDetails;
                }
                else {
                    alert('err' + response);
                    goToLoginPage();
                }
            }
            catch (err) {
                alert('err in catch' + err)
                goToLoginPage();
            }
        }
    }

    const goToLoginPage = async () => {
        await clearStorage();
        setTimeout(() => {
            window.location.href = './';
        }, 10);
    }

    const logout = async () => {
        const key = 'id_token';
        const data = {
            id_token_hint: localStorage[key],
            post_logout_redirect_uri: config.LOGOUT_REDIRECT_URI
        }
        if (data.id_token_hint === undefined || data.id_token_hint === null || data.id_token_hint === '') {
            goToLoginPage();
        } else {
            const params = queryString.stringify(data);
            await clearStorage();
            const logoutURL = `${config.BASE_URL}/oauth2/sessions/logout?${params}`;
            setTimeout(() => {
                window.location.href = logoutURL;
            }, 500)
        }
    }
      
    const getUserInfoSSO = async () => {
        if (isAuthenticated() === false)
            return null;
        try {
            const key = 'access_token';
            const options: AxiosRequestConfig = {
                method: 'GET',
                url: `${config.BASE_URL}/idp/userinfo.openid?schema=openid&access_token=${localStorage[key]}`,
            };
            const response = await axios(options);
            const responseOK = response && response.status === 200;
            if (responseOK) {
                return await response.data;
            } else {
            }
        } catch (e) {}
        return null;
    }

    const clearStorage = async () => {
        localStorage.removeItem('expires_in');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('oauth_state');
        localStorage.removeItem('token_type');
        localStorage.removeItem('scope');
        localStorage.removeItem('i18nextLng');
        localStorage.removeItem('id_token');
        localStorage.removeItem('access_token');
        localStorage.removeItem('token-generated');
        localStorage.removeItem('vmWare');
        localStorage.removeItem('logged_user');
        localStorage.clear();
        clearCookie('XSRF-TOKEN');
    }

    return {
        requestAuthCode,
        acquireAccessToken,
        getAccessToken,
        getIdToken,
        getUserInfo,
        isAuthenticated,
        getRefreshToken,
        getLoggedInUserInfo,
        logout,
        getUserId,
        clearStorage,
        getUserInfoSSO,
        getCookie,
        clearCookie,
    }
}

export default AuthProvider;
