import { Button, Input, Modal } from "@scuf/common"

export const RenameFilePopup = (props: any) => {
    const { open, setOpen, hideInput, hideInputValMsg, newFileName, renameFileExtension, handleNewFileName, newFileValidationLength, newFileValidationMsg, oldFileValidationMsg, renameFileApiCallEvent } = props;
    return (
        <Modal className='modal-content-dataManagement-wrapper' closeIcon={true} onClose={() => setOpen(false)} open={open} closeOnDimmerClick={false} closeOnDocumentClick={false}>
            <div className='inputFlexCss'>
                {
                    hideInput ? <p className='newFileVal'>{hideInputValMsg}</p> :
                        <div style={{ marginLeft: '20px', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Input max={50} className='inputbox-rename' id="inputbox-rename" placeholder='Enter Value' value={newFileName} onChange={(e) => handleNewFileName(e)} />
                                <label style={{ padding: '76px 0px 0px 10px', color: '#fff' }}>{renameFileExtension === ".undefined" || renameFileExtension === null ? "" : renameFileExtension}</label>
                            </div>
                            <h4>
                                <p className='newFileVal'>{newFileValidationLength}{newFileValidationMsg}{oldFileValidationMsg}</p>
                            </h4>
                        </div>
                }
            </div>

            {/* </Modal.Content> */}
            <Modal.Footer className='modal-footer-dataManagement'>
                <Button type="secondary" size="small" content="Cancel" onClick={() => setOpen(false)} className='cancel-secondary-dataManagement' />
                <Button type="primary" disabled={!newFileName} size="small" content="Submit" onClick={() => renameFileApiCallEvent()} className={!newFileName ? 'save-disable-secondary-dataManagement' : 'save-secondary-dataManagement'} />
            </Modal.Footer>
        </Modal>);
}